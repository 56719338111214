import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import authService from "../../services/authService";
import usersService from "../../services/usuariosService";
//import Logo from '../../assets/images/AdminLTELogo.png'

const NavBar = () => {
  const [currentRole, setCurrentRole] = useState(null);
  const [currentUserData, setCurrentUserData] = useState([]);

  const navigate = useNavigate();

  const logOut = () => {
    authService.logout();
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    const role = authService.getCurrentRole();
    const userId = authService.getCurrentUserId();

    if (role) {
      setCurrentRole(role);
    }

    if (userId) {
      usersService.getUserById(userId)
      .then(function(respuesta){
        setCurrentUserData(respuesta.data[0]);
      })
      .catch(function(error){
        console.log(error)
      });
    }


  }, []);

  return (
    <nav className="main-header navbar navbar-expand-md  navbar-white">
      <div className="container">
        {/* <Link to="/dashboard" className="navbar-brand">

          <img src={Logo} alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{opacity: .8}}/>

          <span className="brand-text font-weight-light navbar-brand">PizarraSGI</span>
        </Link> */}
        <span className="brand-text font-weight-light navbar-brand">INTRANET WEB</span>
        <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        {currentRole && (
        <div className="collapse navbar-collapse order-3" id="navbarCollapse">
          <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/dashboard" className="nav-link" >Inicio</Link>
              </li>
              
              <li className="nav-item">
                <Link to="/orders" className="nav-link" >Pedidos</Link>
              </li>
              
              {currentRole !== 3 && (
              <li className="nav-item">
                <Link to="/deals" className="nav-link">Repartos</Link>
              </li>
              )}

              { currentRole !== 3 &&(
              <li className="nav-item">
                  <Link to="/drivers" className="nav-link">Choferes</Link>
              </li>
              )}
              
              { currentRole === 1  && (
                <li className="nav-item dropdown">
                  <Link id="dropdownSubMenu1" to="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Configuracion</Link>
                  <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                      <li><Link to="/users" className="dropdown-item">Usuarios </Link></li>
                      <li><Link to="/notifications" className="dropdown-item">Notificaciones</Link></li>
                      <li><Link to="/vehicle" className="dropdown-item">Vehiculos</Link></li>
                      <li><Link to="/zonas" className="dropdown-item">Zonas</Link></li>
                      <li><Link to="/estados" className="dropdown-item">Estados</Link></li>
                  </ul>
                </li>
              )}
          </ul>

          <ul className="order-1 order-md-3 navbar-nav ml-auto ">
            <li className="nav-item dropdown">
              <Link className="nav-link" data-toggle="dropdown" to="#">
                {currentUserData && (
                <span className="hidden-xs">{currentUserData.lastname}, {currentUserData.name}  </span>
                )}
                <i className="fas fa-caret-down ml-1"></i>
              </Link>
              <div className="dropdown-menu dropdown-menu-md dropdown-menu-right border-0 shadow">
                <Link to="/profile" className="dropdown-item">
                  Mi perfil
                </Link>
                <Link to="/" className="dropdown-item" onClick={logOut}>
                  Cerrar sesión
                </Link>
              </div>

            </li>
          </ul>
          
        </div>
      )}
      </div>
    </nav>
    
  )
}

export default NavBar
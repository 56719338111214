import React, { useEffect, useState } from "react";
import authService from "../../../services/authService";
import notificationService from "../../../services/notificacionesService";
import orderService from "../../../services/orderService";
import FullWidthLayout from "../../layouts/FullWidthLayout";
import { Link } from "react-router-dom";
//import jwt_decode from "jwt-decode";


const Dashboard = () => {
  const [notificaciones, setNotificaciones] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(undefined);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const user = authService.getCurrentUser();

    if (!user) {
      window.location.href="./";
    }

    getNotification();
    getOrders();

  }, []);

  const getExpireToken = () => {
    if (!message){
      localStorage.removeItem("user");
      localStorage.removeItem("role");
      localStorage.removeItem("userId");
    }
    return window.location.href="./";
  }

  const getNotification = () => {
    try {
      notificationService.filterNotification(true)
      .then((response) => {
          setNotificaciones(response.data);
          setLoading(true);
      },
      (error) => {
        console.log(error);
        setMessage(error.response.data.message)
        getExpireToken()
      }
    );
  } catch (err) {
    console.log(err);
  }
}

  const getOrders = () => {
    try {
      orderService.getAllOrders()
      .then((response) => {
          setOrders(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }


  const countPendiente = () => {
    let countPendiente = 0;
      orders.map(order => {
        if (order.idestadopedido == 1) {
          return countPendiente++;
        }
      });
    return countPendiente
  };

  const countFabricacion = () => {
    let countFabricacion = 0;
      orders.map(order => {
        if (order.idestadopedido == 2) {
          return countFabricacion++;
        }
      });
    return countFabricacion
  };

  const countPausados = () => {
    let countPausado = 0;
      orders.map(order => {
        if (order.idestadopedido == 3) {
          return countPausado++;
        }
      });
    return countPausado
  };

  const countCancelados = () => {
    let countCancelados = 0;
      orders.map(order => {
        if (order.idestadopedido == 4) {
          return countCancelados++;
        }
      });
    return countCancelados
  };


  return (
    
    <FullWidthLayout>
      <div className="content-wrapper">
          <div className="content-header">
            <div className="container">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h3 className="m-0"> Inicio</h3>
                </div>
              </div>
            </div>
          </div>

          {!loading ? (
              <div className="custom-loader" style={{marginTop: '150px'}}></div>
          ) : (
          <div className="content">
            <div className="container">
              <div className='row'>
              <div className='col-lg-3'> 
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Notificaciones</h3>
                      </div>
                      {notificaciones.length > 0 ? (
                        <div className="card-body">
                          {notificaciones.map((notificacion, index) => {
                            return (
                              <div key={notificacion.id} className="callout callout-notification">
                                <h5>{notificacion.title}</h5>
                                <p>{notificacion.description}</p>
                              </div>
                            );
                          })}
                        </div> 
                      ) : (
                        <div className="row pt-3">
                          <div className="col-lg-12">
                            <p color="info" className="text-center">
                                No se han encontrado notificaciones.
                            </p>
                          </div>
                        </div>
                      )}
                      
                    </div>
                </div> 
                <div className='col-lg-9'> 
                  <div className='row'> 
                    <div className='col-lg-12'>
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Pedidos</h3>
                        </div>
                        <div className="row">
                          <div className="col-sm-3 col-6">
                            <div className="description-block border-right">
                              <span className="description-percentage text-order"><i className="fas fa-caret-down"></i> </span>
                              <h5 className="description-header">{countPendiente()}</h5>
                              <span className="description-text">PENDIENTES</span>
                            </div>
                          </div>
      
                          <div className="col-sm-3 col-6">
                            <div className="description-block border-right">
                              <span className="description-percentage text-order"><i className="fas fa-caret-down"></i> </span>
                              <h5 className="description-header">{countFabricacion()}</h5>
                              <span className="description-text">EN FABRICACION</span>
                            </div>
                          </div>
      
                          <div className="col-sm-3 col-6">
                            <div className="description-block border-right">
                              <span className="description-percentage text-order"><i className="fas fa-caret-down"></i> </span>
                              <h5 className="description-header">{countPausados()}</h5>
                              <span className="description-text">PAUSADOS</span>
                            </div>
                          </div>
                        
                          <div className="col-sm-3 col-6">
                            <div className="description-block">
                              <span className="description-percentage text-order"><i className="fas fa-caret-down"></i> </span>
                              <h5 className="description-header">{countCancelados()}</h5>
                              <span className="description-text">CANCELADOS</span>
                            </div>
                          </div>
      
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'> 
                    <div className='col-lg-12'> 
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Repartos del dia</h3>
                        </div>
                        <div className="card-body table-responsive p-0">
                          <table id="example1" className="table table-bordered table-striped">
                            <thead>
                            <tr>
                              <th>Fecha</th>
                              <th>Cliente</th>
                              <th>Chofer</th>
                              <th>Vehiculo</th>
                              <th>Salida</th>
                              <th>Vuelta</th>
                              
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                  <td>2023-04-04</td>
                                  <td>Frimetal</td>
                                  <td>Martinez, Pedro</td>
                                  <td>MERCEDEZ EOA758</td>
                                  <td>14:10:25</td>
                                  <td>16:20:25</td>
                                </tr>
                                <tr>
                                  <td>2023-04-04</td>
                                  <td>Frimetal</td>
                                  <td>Martinez, Pedro</td>
                                  <td>MERCEDEZ EOA758</td>
                                  <td>14:10:25</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>2023-04-04</td>
                                  <td>Frimetal</td>
                                  <td>Martinez, Pedro</td>
                                  <td>MERCEDEZ EOA758</td>
                                  <td>14:10:25</td>
                                  <td></td>
                                </tr>
                            </tbody>
                          </table>
                        
                        </div>
                        <div className="card-footer text-center">
                          <Link to="/deals" className="text-dark">Ver todos los repartos</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Vencimientos</h3>
                        </div>
                        <div className="card-body table-responsive p-0">
                          <table id="example1" className="table table-bordered table-striped">
                            <thead>
                            <tr>
                              <th>Renovacion</th>
                              <th>Descripcion</th>
                              <th>Sector</th>
                              <th>Vencimiento</th>
                              <th>Estado</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>2023-04-04</td>
                                  <td>Hosting</td>
                                  <td>Oficina</td>
                                  <td><span className="text-danger">en 5 dias</span></td>
                                  <td>Vigente</td>
                                </tr>
                                <tr>
                                  <td>2023-04-04</td>
                                  <td>Telecom</td>
                                  <td>Oficina</td>
                                  <td>en 45 dias</td>
                                  <td>Vigente</td>
                                </tr>
                                <tr>
                                  <td>2023-04-04</td>
                                  <td>Seguro JZF517</td>
                                  <td>Vehiculo</td>
                                  <td>en 90 dias</td>
                                  <td>Vigente</td>
                                </tr>
                            </tbody>
                          </table>
                        
                        </div>
                        <div className="card-footer text-center">
                          <Link to="/documentation" className="text-dark">Ver todos los vencimientos</Link>
                        </div>
                    </div>
                    </div>

                  </div>
   
                </div>

                
              </div>
            </div>
          </div>
          )}
      
      </div>
    </FullWidthLayout>
  );
};

export default Dashboard;
import React, { useState, useEffect, useRef} from "react";
import usersService from "../../../services/usuariosService";
import authService from "../../../services/authService";
import FullWidthLayout from "../../layouts/FullWidthLayout";

import { showalert } from "../../functions/Function";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const Users = () => {

  const closeModal = useRef();

  const [usuarios, setUsuarios] = useState([]);
  const [searchApellido, setSearchApellido] = useState([]);
  const [searchEmail, setSearchEmail] = useState([]);
  const [tablaUsuarios, setTablaUsuarios]= useState([]);
  const [currentPage, setCurrentPage]= useState(1);
  const [loading, setLoading] = useState(undefined);

  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [lastname, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [codigosector, setCodigoSector] = useState('')
  const [isactive, setIsActive] = useState(true)
  const [createdat, setCreatedAt] = useState('')

  const [operation, setOperation] = useState(1);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const user = authService.getCurrentUser();

    if (!user) {
      window.location.href="./";
    }

    getUsers();

  }, []);

  const getExpireToken = () => {
    if (!message){
      localStorage.removeItem("user");
      localStorage.removeItem("role");
      localStorage.removeItem("userId");
    }
    return window.location.href="./";
  }

  const getUsers = () => {
      try {
        usersService.getAllUsers()
        .then((response) => {
            setUsuarios(response.data);
            setTablaUsuarios(response.data);
            setLoading(true);
          },
          (error) => {
            console.log(error);
            setMessage(error.response.data.message)
            getExpireToken()
          }
        );
      } catch (err) {
        console.log(err);
      }
  }

  /* Busqueda por ... */
  const handleSearchApellido = (e) =>{
    setSearchApellido(e.target.value);
    filtrar(e.target.value);
  }

  const handleSearchEmail = (e) =>{
    setSearchEmail(e.target.value);
    filtrar(e.target.value);
  }

  const filtrar=(terminoBusqueda)=>{
    var resultadosBusqueda=tablaUsuarios.filter((elemento)=>{
      if(
           elemento.lastname.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
        || elemento.email.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
      ){
        return elemento;
      }
    });
    setUsuarios(resultadosBusqueda);
  }

  /* Fin Busqueda por  */


  const openModal = (
    op,
    id,
    name, 
    lastname, 
    email, 
    password, 
    codigosector, 
    isactive, 
    createdat
    ) =>{
      setId('');
      setName('');
      setLastName('');
      setEmail('');
      setPassword('');
      setConfirmPassword('')
      setCodigoSector('');
      setIsActive('');
      setCreatedAt('');

      setOperation(op);
      if (op === 1){
        setTitle("Nuevo usuario");
      }
      else if (op === 2){
        setTitle("Actualizar usuario");
        setId(id)
        setName(name);
        setLastName(lastname);
        setEmail(email);
        setPassword('');
        setConfirmPassword('')
        setCodigoSector(codigosector);
        setIsActive(isactive);
        setCreatedAt(createdat);
      }
  }

  const validate = () =>{
    if (name === ''){
      showalert('Nombre', 'warning')
    }
    else if (lastname === ''){
      showalert('Apellido', 'warning')
    }
    else if (email === ''){
      showalert('Email', 'warning')
    }
    else if (codigosector === ''){
      showalert('Sector/Area', 'warning')
    }
    else if (password === ''){
      showalert('Contraseña', 'warning')
    }
    else if (password !== confirmPassword){
      showalert('Las contraseñas no coinciden', 'warning')
    }
    else if (password.length < 6){
      showalert('La contraseña debe contener al menos 6 caracteres', 'warning')
    }
    else{
        enviarSolicitud();
    }
  }

  const enviarSolicitud = async() =>{
      if(operation === 1){
        authService
          .createUser(
          name, 
          lastname, 
          email, 
          password, 
          codigosector, 
          isactive, 
          createdat)
          .then(function(respuesta){
          var msg = respuesta.data.message;
          showalert(msg, 'success')
          onButtonClick();
          getUsers();
        })
        .catch(function(error){
          showalert('Error en la solicitud', 'error');
          console.log(error)
        });
      }else if (operation === 2){
        usersService
          .updateUser(
          id,
          name, 
          lastname, 
          email, 
          password, 
          codigosector, 
          isactive, 
          createdat)
          .then(function(respuesta){
          var msg = respuesta.data.message;
          showalert(msg, 'success')
          onButtonClick();
          getUsers();
        })
        .catch(function(error){
          showalert('Error en la solicitud', 'error');
          console.log(error)
        });
      }
      
  }

  const deleteUser= (id) => {
    const mySwal = withReactContent(Swal);
    mySwal.fire({
        title: '¿Seguro de eliminar el usuario?',
        icon: 'question', 
        showCancelButton: true, confirmButtonText: 'Si, eliminar', cancelButtonText: 'Cancelar'
    })
    .then((result) => {
      if (result.isConfirmed){
        setId(id);
        usersService.deleteUser(id);
        getUsers();
      }else{
        showalert('El usuario no fue eliminado.', 'warning');
      }
      
    })
    
  }

  const onButtonClick = () => {
    closeModal.current.click();
  };

  //PAGINADO
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;

  const records = usuarios.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(usuarios.length / recordsPerPage)

  const numbers = [...Array(nPage + 1).keys()].slice(1)

  const nextPage = () =>{
    if (currentPage !== 1){
      setCurrentPage(currentPage - 1)
    }
  }

  const changeCPage = (id) =>{
      setCurrentPage(id)
  }

  const prevPage = () =>{
    if (currentPage !== nPage){
      setCurrentPage(currentPage + 1)
    }
  }
  
  return (
    <FullWidthLayout>
      <div className="content-wrapper">
          <div className="content-header">
            <div className="container">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h3 className="m-0"> Usuarios</h3>
                </div>
                <div className="col-sm-6">
                  <div className="float-sm-right">
                    <button
                        onClick={() => openModal(1)}
                        className="btn btn-primary btn-sm" 
                        data-toggle="modal" 
                        data-target="#modal-lg"
                        >
                        <i className="fa fa-plus" aria-hidden="true"></i> Nuevo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> 

          {!loading ? (
              <div className="custom-loader" style={{marginTop: '150px'}}></div>
          ) : (
          <div className="content">
            <div className="container">
              <div className="row mb-3">
                  <div className="col-lg-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Filtrar por</h3>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6 mb-2">
                                  <label>Apellido</label>
                                  <input 
                                    type="text"
                                    value={searchApellido} 
                                    name="table_search" 
                                    className="form-control" 
                                    onChange={handleSearchApellido}
                                  />
                                </div>
                                <div className="col-sm-6">
                                  <label>Email</label>
                                  <input 
                                    type="text"
                                    value={searchEmail} 
                                    name="table_search" 
                                    className="form-control" 
                                    onChange={handleSearchEmail}
                                  />
                                </div>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Listado de usuarios</h3>
                        {/*}
                        <div className="card-tools">
                          <div className="input-group input-group-sm" style={{width: '180px'}}>
                            <input 
                              type="text"
                              value={busqueda} 
                              name="table_search" 
                              className="form-control float-right" 
                              placeholder="Nombre o email..." 
                              onChange={handleSearch}
                            />

                            <div className="input-group-append">
                              <button type="submit" className="btn btn-default">
                                <i className="fas fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        {*/}
                      </div>
                      <div className="card-body table-responsive p-0">
                      {usuarios.length > 0 ? (
                        <table id="example1" className="table table-bordered table-striped">
                          <thead>
                          <tr>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Email</th>
                            <th>Sector/Area</th>
                            <th>Activo</th>
                            <th>Acciones</th>
                          </tr>
                          </thead>
                          <tbody>
                          {records.map((user, index) => {
                            return (
                              <tr key={user.id}>
                                <td>{user.name}</td>
                                <td>{user.lastname}</td>
                                <td>{user.email}</td>
                                <td>{user.sector}</td>
                                <td>
                                  {user.isactive ?
                                  <span className="badge bg-success">Si</span>
                                  :
                                  <span className="badge bg-danger">No</span>}
                                </td>
                                <td>
                                  <button
                                      onClick={() => openModal(
                                        2,
                                        user.id,
                                        user.name,
                                        user.lastname,
                                        user.email,
                                        user.password,
                                        user.codigosector,
                                        user.isactive
                                      )}
                                      className="btn btn-warning btn-xs" 
                                      data-toggle="modal" 
                                      data-target="#modal-lg"
                                      >
                                      Editar
                                  </button>
                                  {" "}
                                  <button
                                      onClick={() => deleteUser(
                                        user.id,
                                      )}
                                      className="btn btn-danger btn-xs" 
                                      
                                      >
                                      Borrar
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                          </tbody>
                        </table>
                        ) : (
                          <div className="row">
                            <div className="col-lg-12">
                              <p color="info" className="text-center">
                                  No se han encontrado usuarios.
                              </p>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="card-footer clearfix">
                        <ul className="pagination pagination-sm m-0 float-right">
                          <li className="page-item">
                            <a className="page-link" href="#" onClick={prevPage}>&laquo;</a>
                          </li>
                          {
                            numbers.map((n,i) =>(
                            <li key={i} className={`page-item ${currentPage === n ? 'active' : ''}`}>
                              <a 
                                className="page-link" 
                                href="#"
                                onClick={()=>changeCPage(n)}
                              >{n}</a>
                            
                            </li>
                            ))
                          }
                          <li className="page-item">
                            <a className="page-link" href="#" onClick={nextPage}>&raquo;</a>
                          </li>
                        </ul>
                      </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
          )}

          <div className="modal fade" id="modal-lg">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                    <h4 className="modal-title">{title}</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                      <div className="modal-body">
                        <div className="card-body">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                  <input type="hidden" id="id" />
                                  <label>Nombre</label>
                                  <input
                                    id="name" 
                                    name="name"  
                                    type="text" 
                                    className="form-control"
                                    onChange={(e) => setName(e.target.value)} 
                                    value={name ? name : ''}
                                  />
                                </div>
                                <div className="col-sm-6">
                                  <label>Apellido</label>
                                  <input
                                    id="lastname"
                                    name="lastname" 
                                    type="text" 
                                    className="form-control"
                                    onChange={(e) => setLastName(e.target.value)} 
                                    value={lastname ? lastname : ''}
                                  />
                                </div>
                            </div>

                            <div className="row mb-2">
                              <div className="col-sm-6">
                                  <label>Email</label>
                                  <input
                                    id="email"
                                    name="email" 
                                    type="email" 
                                    className="form-control"
                                    onChange={(e) => setEmail(e.target.value)} 
                                    value={email ? email : ''}
                                  />
                                </div>
                                <div className="col-sm-6">
                                  <label>Sector/Area</label>
                                  <select className="form-control" 
                                    id="codigosector"
                                    name="codigosector"
                                    onChange={(e) => setCodigoSector(e.target.value)} 
                                    value={codigosector}
                                  >
                                    <option value=""></option>
                                    <option value={1}>Oficina</option>
                                    <option value={2}>Despacho</option>
                                    <option value={3}>Produccion</option>
                                    <option value={4}>Super admin</option>
                                  </select>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-sm-4">
                                  <label>Nueva contraseña</label>
                                  <input
                                    id="password" 
                                    name="password"  
                                    type="password" 
                                    className="form-control"
                                    onChange={(e) => setPassword(e.target.value)} 
                                    value={password ? password : ''}
                                  />
                                </div>
                                <div className="col-sm-4">
                                  <label>Confirmar contraseña</label>
                                  <input
                                    id="confirmPassword" 
                                    name="confirmPassword"  
                                    type="password" 
                                    className="form-control"
                                    onChange={(e) => setConfirmPassword(e.target.value)} 
                                    value={confirmPassword ? confirmPassword : ''}
                                  />
                                </div>
                                <div className="col-sm-4">
                                  <label>Activo</label>
                                  <select className="form-control" 
                                    id="isactive"
                                    name="isactive"
                                    onChange={(e) => setIsActive(e.target.value)} 
                                    value={isactive}
                                  >
                                    <option value=""></option>
                                    <option value={true}>Si</option>
                                    <option value={false}>No</option>
                                  </select>
                                </div>
                            </div>
                            {
                             operation === 2 && (
                              <div className="row mt-4">
                                  <p>* Para actualizar los datos, debera ingesar la contraseña actual del usuario o asignar una nueva.</p>
                              </div>
                            ) }
                            
                        </div>
                    
                      </div>
                      
                      <div className="modal-footer pull-right">
                        <button type="submit" onClick={() => validate()} className="btn btn-primary">Guardar</button>
                        <button type="button" ref={closeModal} className="btn btn-default" data-dismiss="modal">Cerrar</button>
                      </div>
                </div>
                
                </div>
                
            </div>
      </div>
    </FullWidthLayout>
  );
};

export default Users;
import axios from "axios";
import authHeader from "./authHeader";
import BASE_URL from '../conf'

const getAllOrders = async() => {
  return await axios.get(BASE_URL + "orders", { headers: authHeader() });
};

const createOrder = async(
  fechapedido, 
  cliente, 
  nv,
  cantmetros,
  referencia,
  pospuesto, 
  remite, 
  idzonareparto, 
  idestadopedido, 
  observacion,
  idusuario, 
  fechaentrega) => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    //headers:{ "Content-Type": "application/json" },
    //body: JSON.stringify(data),
  };
  return  await axios.post(BASE_URL + "orders", {
    fechapedido, 
    cliente, 
    nv,
    cantmetros,
    referencia,
    pospuesto, 
    remite, 
    idzonareparto, 
    idestadopedido, 
    observacion,
    idusuario,
    fechaentrega
  }, requestOptions);
};

const updateOrder = async(
  id,
  fechapedido, 
  cliente, 
  nv,
  cantmetros,
  referencia, 
  pospuesto, 
  remite, 
  idzonareparto, 
  idestadopedido, 
  observacion,
  idusuario,
  fechaentrega) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };
  return  await axios.put(BASE_URL + "orders/" + `${id}`, {
    fechapedido, 
    cliente, 
    nv,
    cantmetros,
    referencia, 
    pospuesto, 
    remite, 
    idzonareparto, 
    idestadopedido, 
    observacion,
    idusuario,
    fechaentrega
  }, requestOptions);
};

const deleteOrder = async(id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    //headers:{ "Content-Type": "application/json" },
  };
  return  await axios.delete(BASE_URL + "orders/" + `${id}`, requestOptions);
  //return await axios.post(BASE_URL + "orders", { headers: authHeader() });
};

const historyOrder = async(id) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };
  return  await axios.put(BASE_URL + "orders/history/" + `${id}`, requestOptions);
};

const orderService = {
  getAllOrders,
  createOrder,
  updateOrder,
  deleteOrder,
  historyOrder
};

export default orderService;
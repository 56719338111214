import React, { useEffect } from "react";
import authService from "../../../services/authService";
import FullWidthLayout from "../../layouts/FullWidthLayout";

const Document = () => {
  
  useEffect(() => {
    const user = authService.getCurrentUser();

    if (!user) {
      window.location.href="./";
    }
  }, []);

  return (
    
    <FullWidthLayout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h3 className="m-0"> Documentacion</h3>
              </div>
              
            </div>
          </div>
        </div>
  
      </div>
    </FullWidthLayout>
  );
};

export default Document;
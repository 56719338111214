import axios from "axios";
import authHeader from "./authHeader";
import BASE_URL from '../conf'

const createUser = async(
  name, 
  lastname, 
  email, 
  password, 
  codigosector, 
  isactive, 
  createdat) => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return  await axios.post(BASE_URL + "auth/register", {
    name, 
    lastname, 
    email, 
    password, 
    codigosector, 
    isactive, 
    createdat
  }, requestOptions);
};

const login = async (username, password) => {
  return await axios
    .post(BASE_URL + "auth/login", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.data.token));
        localStorage.setItem("role", JSON.stringify(response.data.data.codigosector));
        localStorage.setItem("userId", JSON.stringify(response.data.data.id));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("role");
  localStorage.removeItem("userId");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getCurrentRole = () => {
  return JSON.parse(localStorage.getItem("role"));
};

const getCurrentUserId = () => {
  return JSON.parse(localStorage.getItem("userId"));
};

const authService = {
  createUser,
  login,
  logout,
  getCurrentUser,
  getCurrentRole,
  getCurrentUserId
};

export default authService;
import React from 'react'

const Footer = () => {
  return (
    <footer className="main-footer">
        <div className="content">
            <div className="container">
                <div className="row">
                    Copyright &copy; 2023 Todopor SRL.
                </div>
             </div>
        </div>
    </footer>
  )
}

export default Footer
import React, { useState, useEffect, useRef} from "react";
import choferesService from "../../../services/choferesService";
import authService from "../../../services/authService";
import FullWidthLayout from "../../layouts/FullWidthLayout";

import { showalert, formateoFecha } from "../../functions/Function";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const Drivers = () => {

  const closeModal = useRef();

  const [choferes, setChoferes] = useState([]);
  const [searchApellido, setSearchApellido] = useState([]);
  const [searchIsActive, setSearchIsActive] = useState([]);
  const [tablaChoferes, setTablaChoferes]= useState([]);
  const [currentPage, setCurrentPage]= useState(1);
  const [loading, setLoading] = useState(undefined);

  const [id, setId] = useState('')
  const [nombre, setNombre] = useState('')
  const [apellido, setApellido] = useState('')
  const [claselicencia, setClaseLicencia] = useState('')
  const [vtolicencia, setVtoLicencia] = useState('')
  const [grupofactor, setGrupoFactor] = useState('')
  const [celular, setCelular] = useState('')
  const [observacion, setObservacion] = useState('')
  const [isactive, setIsActive] = useState('')

  const [operation, setOperation] = useState(1);
  const [title, setTitle] = useState('');
  
  const [currentRole, setCurrentRole] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const user = authService.getCurrentUser();
    const role = authService.getCurrentRole();

    if (!user) {
      window.location.href="./";
    }

    if (role) {
      setCurrentRole(role);
    }

    getChoferes();

  }, []);

  const getExpireToken = () => {
    if (!message){
      localStorage.removeItem("user");
      localStorage.removeItem("role");
      localStorage.removeItem("userId");
    }
    return window.location.href="./";
  }

  const getChoferes = () => {
      try {
        choferesService.getAllChoferes()
        .then((response) => {
            setChoferes(response.data);
            setTablaChoferes(response.data);
            setLoading(true);
          },
          (error) => {
            console.log(error);
            setMessage(error.response.data.message)
            getExpireToken()
          }
        );
      } catch (err) {
        console.log(err);
      }
  }

  /* Busqueda por ... */
  const handleSearchApellido = (e) =>{
    setSearchApellido(e.target.value);
    filtrar(e.target.value);
  }

  const handleSearchIsActive = (e) =>{
    setSearchIsActive(e.target.value);
    filtrar(e.target.value);
  }

  const filtrar=(terminoBusqueda)=>{
    var resultadosBusqueda=tablaChoferes.filter((elemento)=>{
      if(
          elemento.apellido.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
       || elemento.isactive.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
      ){
        return elemento;
      }
    });
    setChoferes(resultadosBusqueda);
  }

  /* Fin Busqueda por  */


  const openModal = (
    op,
    id,
    nombre, 
    apellido, 
    claselicencia, 
    vtolicencia, 
    grupofactor, 
    celular, 
    observacion, 
    isactive
    ) =>{
      setId('');
      setNombre('');
      setApellido('');
      setClaseLicencia('');
      setVtoLicencia('');
      setGrupoFactor('');
      setCelular('');
      setObservacion('');
      setIsActive('');

      setOperation(op);
      if (op === 1){
        setTitle("Nuevo chofer");
      }
      else if (op === 2){
        setTitle("Actualizar chofer");
        setId(id)
        setNombre(nombre);
        setApellido(apellido);
        setClaseLicencia(claselicencia);
        setVtoLicencia(vtolicencia);
        setGrupoFactor(grupofactor);
        setCelular(celular);
        setObservacion(observacion);
        setIsActive(isactive);
      }
  }

  const validate = () =>{
    if (nombre === ''){
      showalert('Nombre', 'warning')
    }
    else if (apellido === ''){
      showalert('Apellido', 'warning')
    }
    else if (claselicencia === ''){
      showalert('Clase de licencia', 'warning')
    }
    else if (vtolicencia === ''){
      showalert('Vencimiento de licencia', 'warning')
    }
    else if (grupofactor === ''){
      showalert('Sangre grupo y factor', 'warning')
    }
    else if (celular === ''){
      showalert('Celular de contacto', 'warning')
    }
    else{
        enviarSolicitud();
    }
  }

  const enviarSolicitud = async() =>{
      if(operation === 1){
        choferesService
          .createChofer(
            nombre, 
            apellido, 
            claselicencia, 
            vtolicencia, 
            grupofactor, 
            celular, 
            observacion, 
            isactive)
          .then(function(respuesta){
          var msg = respuesta.data.message;
          showalert(msg, 'success')
          onButtonClick();
          getChoferes();
        })
        .catch(function(error){
          showalert('Error en la solicitud', 'error');
          console.log(error)
        });
      }else if (operation === 2){
        choferesService
          .updateChofer(
            id,
            nombre, 
            apellido, 
            claselicencia, 
            vtolicencia, 
            grupofactor, 
            celular, 
            observacion, 
            isactive)
          .then(function(respuesta){
          var msg = respuesta.data.message;
          showalert(msg, 'success')
          onButtonClick();
          getChoferes();
        })
        .catch(function(error){
          showalert('Error en la solicitud', 'error');
          console.log(error)
        });
      }
      
  }

  const deleteChofer = (id) => {
    const mySwal = withReactContent(Swal);
    mySwal.fire({
        title: '¿Seguro de eliminar el chofer?',
        icon: 'question', 
        showCancelButton: true, confirmButtonText: 'Si, eliminar', cancelButtonText: 'Cancelar'
    })
    .then((result) => {
      if (result.isConfirmed){
        setId(id);
        choferesService.deleteChofer(id);
        getChoferes();
      }else{
        showalert('El chofer no fue eliminado.', 'warning');
      }
      
    })
    
  }

  const onButtonClick = () => {
    closeModal.current.click();
  };

  //PAGINADO
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;

  const records = choferes.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(choferes.length / recordsPerPage)

  const numbers = [...Array(nPage + 1).keys()].slice(1)

  const nextPage = () =>{
    if (currentPage !== 1){
      setCurrentPage(currentPage - 1)
    }
  }

  const changeCPage = (id) =>{
      setCurrentPage(id)
  }

  const prevPage = () =>{
    if (currentPage !== nPage){
      setCurrentPage(currentPage + 1)
    }
  }
  
  return (
    <FullWidthLayout>
      <div className="content-wrapper">
          <div className="content-header">
            <div className="container">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h3 className="m-0"> Choferes </h3>
                </div>
                {currentRole === 1 && (
                <div className="col-sm-6">
                  <div className="float-sm-right">
                    <button
                        onClick={() => openModal(1)}
                        className="btn btn-primary btn-sm" 
                        data-toggle="modal" 
                        data-target="#modal-lg"
                        >
                        <i className="fa fa-plus" aria-hidden="true"></i> Nuevo
                    </button>
                  </div>
                </div>
                )}
              </div>
            </div>
          </div> 

          {!loading ? (
              <div className="custom-loader" style={{marginTop: '150px'}}></div>
          ) : (
          <div className="content">
            <div className="container">
              <div className="row mb-3">
                  <div className="col-lg-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Filtrar por</h3>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6 mb-2">
                                  <label>Apellido</label>
                                  <input 
                                    type="text"
                                    value={searchApellido} 
                                    name="table_search" 
                                    className="form-control" 
                                    onChange={handleSearchApellido}
                                  />
                                </div>
                                <div className="col-sm-6">
                                  <label>Activo</label>
                                  <select className="form-control"
                                    name="table_search"
                                    onChange={handleSearchIsActive} 
                                    value={searchIsActive}
                                  >
                                    <option value=""></option>
                                    <option value={true}>Si</option>
                                    <option value={false}>No</option>
                                  </select>
                                </div>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Listado de choferes</h3>
                        {/*}
                        <div className="card-tools">
                          <div className="input-group input-group-sm" style={{width: '180px'}}>
                            <input 
                              type="text"
                              value={busqueda} 
                              name="table_search" 
                              className="form-control float-right" 
                              placeholder="Apellido..." 
                              onChange={handleSearch}
                            />

                            <div className="input-group-append">
                              <button type="submit" className="btn btn-default">
                                <i className="fas fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        {*/}
                      </div>
                      <div className="card-body table-responsive p-0">
                      {choferes.length > 0 ? (
                        <table id="example1" className="table table-bordered table-striped">
                          <thead>
                          <tr>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Licencia clase</th>
                            <th>Vto. licencia</th>
                            <th>Sangre grupo/factor</th>
                            <th>Celular</th>
                            <th>Activo</th>
                            {currentRole === 1 && (
                            <th>Acciones</th>
                            )}
                          </tr>
                          </thead>
                          <tbody>
                          {records.map((chofer, index) => {
                            return (
                              <tr key={chofer.id}>
                                <td>{chofer.nombre}</td>
                                <td>{chofer.apellido}</td>
                                <td>{chofer.claselicencia}</td>
                                <td>{formateoFecha(chofer.vtolicencia)}</td>
                                <td>{chofer.grupofactor}</td>
                                <td>{chofer.celular}</td>
                                <td>
                                  {chofer.isactive ?
                                  <span className="badge bg-success">Si</span>
                                  :
                                  <span className="badge bg-danger">No</span>}
                                </td>
                                {currentRole === 1 && (
                                <td style={{width: '180px'}}>
                                  <button
                                      onClick={() => openModal(
                                        2,
                                        chofer.id,
                                        chofer.nombre,
                                        chofer.apellido,
                                        chofer.claselicencia,
                                        formateoFecha(chofer.vtolicencia),
                                        chofer.grupofactor,
                                        chofer.celular,
                                        chofer.observacion,
                                        chofer.isactive
                                      )}
                                      className="btn btn-warning btn-xs" 
                                      data-toggle="modal" 
                                      data-target="#modal-lg"
                                      >
                                      Editar
                                  </button>
                                  {" "}
                                  <button
                                      onClick={() => deleteChofer(
                                        chofer.id,
                                      )}
                                      className="btn btn-danger btn-xs" 
                                      
                                      >
                                      Borrar
                                  </button>
                                </td>
                                )}
                              </tr>
                            );
                          })}
                          </tbody>
                        </table>
                        ) : (
                          <div className="row">
                            <div className="col-lg-12">
                              <p color="info" className="text-center">
                                  No se han encontrado choferes.
                              </p>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="card-footer clearfix">
                        <ul className="pagination pagination-sm m-0 float-right">
                          <li className="page-item">
                            <a className="page-link" href="#" onClick={prevPage}>&laquo;</a>
                          </li>
                          {
                            numbers.map((n,i) =>(
                            <li key={i} className={`page-item ${currentPage === n ? 'active' : ''}`}>
                              <a 
                                className="page-link" 
                                href="#"
                                onClick={()=>changeCPage(n)}
                              >{n}</a>
                            
                            </li>
                            ))
                          }
                          <li className="page-item">
                            <a className="page-link" href="#" onClick={nextPage}>&raquo;</a>
                          </li>
                        </ul>
                      </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
          )}

          <div className="modal fade" id="modal-lg">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                    <h4 className="modal-title">{title}</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                      <div className="modal-body">
                        <div className="card-body">
                            <div className="row mb-2">
                                <div className="col-sm-4">
                                  <input type="hidden" id="id" />
                                  <label>Nombre</label>
                                  <input
                                    id="nombre"
                                    name="nombre" 
                                    type="text" 
                                    className="form-control"
                                    onChange={(e) => setNombre(e.target.value)} 
                                    value={nombre ? nombre : ''}
                                  />
                                </div>
                                <div className="col-sm-4">
                                  <label>Apellido</label>
                                  <input
                                    id="apellido"
                                    name="apellido" 
                                    type="text" 
                                    className="form-control"
                                    onChange={(e) => setApellido(e.target.value)} 
                                    value={apellido ? apellido : ''}
                                  />
                                </div>
                                <div className="col-sm-4">
                                  <label>Clase de licencia</label>
                                  <input
                                    id="claselicencia"
                                    name="claselicencia" 
                                    type="text" 
                                    className="form-control"
                                    onChange={(e) => setClaseLicencia(e.target.value)} 
                                    value={claselicencia ? claselicencia : ''}
                                  />
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-sm-4">
                                  <label>Vencimiento de licencia</label>
                                  <input
                                    id="vtolicencia"
                                    name="vtolicencia" 
                                    type="date" 
                                    className="form-control"
                                    onChange={(e) => setVtoLicencia(e.target.value)} 
                                    value={vtolicencia ? vtolicencia : ''}
                                  />
                                </div>
                                <div className="col-sm-4">
                                  <label>Sangre grupo y factor</label>
                                  <input
                                    id="grupofactor"
                                    name="grupofactor" 
                                    type="text" 
                                    className="form-control"
                                    onChange={(e) => setGrupoFactor(e.target.value)} 
                                    value={grupofactor ? grupofactor : ''}
                                  />
                                </div>
                                <div className="col-sm-4">
                                  <label>Nro de celular/telefono</label>
                                  <input
                                    id="celular"
                                    name="celular" 
                                    type="text" 
                                    className="form-control"
                                    onChange={(e) => setCelular(e.target.value)} 
                                    value={celular ? celular : ''}
                                  />
                                </div>
                                
                            </div>

                            <div className="row mb-2">
                              <div className="col-sm-12">
                                <label>Observacion</label>
                                <textarea
                                  id="observacion" 
                                  name="observacion" 
                                  className="form-control" 
                                  rows="3"
                                  onChange={(e) => setObservacion(e.target.value)} 
                                  value={observacion}
                                >
                                  
                                </textarea>
                              </div>
                              <div className="col-sm-3">
                                  <label>Activo</label>
                                  <select className="form-control" 
                                    id="isactive"
                                    name="isactive"
                                    onChange={(e) => setIsActive(e.target.value)} 
                                    value={isactive}
                                  >
                                    <option value=""></option>
                                    <option value={true}>Si</option>
                                    <option value={false}>No</option>
                                  </select>
                                </div>
                            </div>

                        </div>
                      
                      </div>
                      
                      <div className="modal-footer pull-right">
                        <button type="submit" onClick={() => validate()} className="btn btn-primary">Guardar</button>
                        <button type="button" ref={closeModal} className="btn btn-default" data-dismiss="modal">Cerrar</button>
                      </div>
                </div>
                
                </div>
                
            </div>
      </div>
    </FullWidthLayout>
  );
};

export default Drivers;
import axios from "axios";
import authHeader from "./authHeader";
import BASE_URL from '../conf'

const getAllNotifications = async() => {
  return await axios.get(BASE_URL + "notifications", { headers: authHeader() });
};

const createNotification = async(
  title, 
  description, 
  isactive) => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return  await axios.post(BASE_URL + "notifications", {
    title, 
    description, 
    isactive
  }, requestOptions);
};

const updateNotification = async(
  id,
  title, 
  description, 
  isactive) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };
  return  await axios.put(BASE_URL + "notifications/" + `${id}`, {
    title, 
    description, 
    isactive
  }, requestOptions);
};

const deleteNotification= async(id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return  await axios.delete(BASE_URL + "notifications/" + `${id}`, requestOptions);
};

const filterNotification = async(
  isactive) => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return  await axios.post(BASE_URL + "notifications/filter", {
    isactive
  }, requestOptions);
};

const notificationsService = {
  getAllNotifications,
  createNotification,
  updateNotification,
  deleteNotification,
  filterNotification
};

export default notificationsService;
import Footer from "./Footer"
import Navbar from "./NavBar"

const FullWidthLayout = ({children}) => {
    return(
        <>
        <Navbar/>
            {children}
        <Footer/>
        </>
    )
}

export default FullWidthLayout
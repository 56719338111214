import React, { useState, useEffect, useRef} from "react";
import DealsService from "../../../services/repartosService";
import vehiculosService from "../../../services/vehiculosService";
import choferesService from "../../../services/choferesService";
import authService from "../../../services/authService";
import FullWidthLayout from "../../layouts/FullWidthLayout";

import { showalert, formateoFecha } from "../../functions/Function";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const Deals = () => {

  const closeModal = useRef();

  const [repartos, setRepartos] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);
  const [choferes, setChoferes] = useState([]);
  const [searchFechaViaje, setSearchFechaViaje] = useState([]);
  const [searchCliente, setSearchCliente] = useState([]);
  const [searchVehiculo, setSearchVehiculo] = useState([]);
  const [searchChofer, setSearchChofer] = useState([]);
  const [tablaRepartos, setTablaRepartos]= useState([]);
  const [currentPage, setCurrentPage]= useState(1);
  const [loading, setLoading] = useState(undefined);

  const [id, setId] = useState('')
  const [fechaviaje, setFechaviaje] = useState('')
  const [cliente, setCliente] = useState('')
  const [idvehiculo, setIdvehiculo] = useState('')
  const [idchofer, setIdchofer] = useState('')
  const [horadesde, setHoraDesde] = useState('')
  const [horahasta, setHoraHasta] = useState('')
  const [observacion, setObservacion] = useState('')

  const [operation, setOperation] = useState(1);
  const [title, setTitle] = useState('');

  const [currentRole, setCurrentRole] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const user = authService.getCurrentUser();
    const role = authService.getCurrentRole();
    
    if (!user) {
      window.location.href="./";
    }

    if (role) {
      setCurrentRole(role);
    }

    getDeals();
    getVehiculos();
    getChoferes();

  }, []);

  const getExpireToken = () => {
    if (!message){
      localStorage.removeItem("user");
      localStorage.removeItem("role");
      localStorage.removeItem("userId");
    }
    return window.location.href="./";
  }

  const getVehiculos = () => {
    try {
      vehiculosService.filterVehiculo(true)
      .then((response) => {
        setVehiculos(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  const getChoferes = () => {
    try {
      choferesService.filterChofer(true)
      .then((response) => {
        setChoferes(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  const getDeals = () => {
      try {
        DealsService.getAllDeals()
        .then((response) => {
            setRepartos(response.data);
            setTablaRepartos(response.data);
            setLoading(true);
          },
          (error) => {
            console.log(error);
            setMessage(error.response.data.message)
            getExpireToken()
          }
        );
      } catch (err) {
        console.log(err);
      }
  }

  /* Busqueda por ... */
  const handleSearchFechaViaje = (e) =>{
    setSearchFechaViaje(e.target.value);
    filtrar(e.target.value);
  }

  const handleSearchCliente = (e) =>{
    setSearchCliente(e.target.value);
    filtrar(e.target.value);
  }

  const handleSearchVehiculo = (e) =>{
    setSearchVehiculo(e.target.value);
    filtrar(e.target.value);
  }

  const handleSearchChofer = (e) =>{
    setSearchChofer(e.target.value);
    filtrar(e.target.value);
  }

  const filtrar=(terminoBusqueda)=>{
    var resultadosBusqueda=tablaRepartos.filter((elemento)=>{
      if(
           elemento.cliente.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
        || elemento.fechaviaje.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
        || elemento.vehiculo.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
        || elemento.apellido.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
      ){
        return elemento;
      }
    });
    setRepartos(resultadosBusqueda);
  }

  /* Fin Busqueda por  */


  const openModal = (
    op,
    id,
    fechaviaje, 
    cliente, 
    idvehiculo, 
    idchofer, 
    horadesde, 
    horahasta, 
    observacion
    ) =>{
      setId('');
      setFechaviaje('');
      setCliente('');
      setIdvehiculo('');
      setIdchofer('');
      setHoraDesde('');
      setHoraHasta('');
      setObservacion('');

      setOperation(op);
      if (op === 1){
        setTitle("Nuevo reparto");
      }
      else if (op === 2){
        setTitle("Actualizar reparto");
        setId(id)
        setFechaviaje(fechaviaje);
        setCliente(cliente);
        setIdvehiculo(idvehiculo);
        setIdchofer(idchofer);
        setHoraDesde(horadesde);
        setHoraHasta(horahasta);
        setObservacion(observacion);
      }
  }

  const validate = () =>{
    if (fechaviaje === ''){
      showalert('Fecha de viaje', 'warning')
    }
    else if (cliente === ''){
      showalert('Nombre del cliente', 'warning')
    }
    else if (idvehiculo === ''){
      showalert('Vehiculo', 'warning')
    }
    else if (idchofer === ''){
      showalert('Chofer', 'warning')
    }
    else if (horadesde === ''){
      showalert('Hora desde', 'warning')
    }
    else if (horahasta === ''){
      showalert('Hora hasta', 'warning')
    }
    else{
        enviarSolicitud();
    }
  }

  const enviarSolicitud = async() =>{
      if(operation === 1){
        DealsService
          .createDeal(
            fechaviaje, 
            cliente, 
            idvehiculo, 
            idchofer, 
            horadesde, 
            horahasta, 
            observacion)
          .then(function(respuesta){
          var msg = respuesta.data.message;
          showalert(msg, 'success')
          onButtonClick();
          getDeals();
        })
        .catch(function(error){
          showalert('Error en la solicitud', 'error');
          console.log(error)
        });
      }else if (operation === 2){
        DealsService
          .updateDeal(
            id,
            fechaviaje, 
            cliente, 
            idvehiculo, 
            idchofer, 
            horadesde, 
            horahasta, 
            observacion)
          .then(function(respuesta){
          var msg = respuesta.data.message;
          showalert(msg, 'success')
          onButtonClick();
          getDeals();
        })
        .catch(function(error){
          showalert('Error en la solicitud', 'error');
          console.log(error)
        });
      }
      
  }

  const deleteDeal = (id) => {
    const mySwal = withReactContent(Swal);
    mySwal.fire({
        title: '¿Seguro de eliminar el reparto?',
        icon: 'question', 
        showCancelButton: true, confirmButtonText: 'Si, eliminar', cancelButtonText: 'Cancelar'
    })
    .then((result) => {
      if (result.isConfirmed){
        setId(id);
        DealsService.deleteDeal(id);
        getDeals();
      }else{
        showalert('El reparto no fue eliminado.', 'warning');
      }
      
    })
    
  }

  const onButtonClick = () => {
    closeModal.current.click();
  };

  //PAGINADO
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;

  const records = repartos.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(repartos.length / recordsPerPage)

  const numbers = [...Array(nPage + 1).keys()].slice(1)

  const nextPage = () =>{
    if (currentPage !== 1){
      setCurrentPage(currentPage - 1)
    }
  }

  const changeCPage = (id) =>{
      setCurrentPage(id)
  }

  const prevPage = () =>{
    if (currentPage !== nPage){
      setCurrentPage(currentPage + 1)
    }
  }
  
  return (
    <FullWidthLayout>
      <div className="content-wrapper">
          <div className="content-header">
            <div className="container">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h3 className="m-0"> Repartos</h3>
                </div>
                {currentRole === 2 && (
                <div className="col-sm-6">
                  <div className="float-sm-right">
                    <button
                        onClick={() => openModal(1)}
                        className="btn btn-primary btn-sm" 
                        data-toggle="modal" 
                        data-target="#modal-lg"
                        >
                        <i className="fa fa-plus" aria-hidden="true"></i> Nuevo
                    </button>
                  </div>
                </div>
                )}
              </div>
            </div>
          </div> 

          {!loading ? (
              <div className="custom-loader" style={{marginTop: '150px'}}></div>
          ) : (
          <div className="content">
            <div className="container">
            
                <div className="row mb-3">
                  <div className="col-lg-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Filtrar por</h3>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-3 mb-2">
                                  <label>Fecha de viaje</label>
                                  <input
                                    type="date"
                                    value={searchFechaViaje}  
                                    name="table_search" 
                                    className="form-control datetimepicker-input"
                                    onChange={handleSearchFechaViaje}
                                  />
                                </div>
                                <div className="col-sm-3 mb-2">
                                  <label>Cliente</label>
                                  <input 
                                    type="text"
                                    value={searchCliente} 
                                    name="table_search" 
                                    className="form-control" 
                                    onChange={handleSearchCliente}
                                  />
                                </div>
                                <div className="col-sm-3 mb-2">
                                  <label>Vehiculo</label>
                                  <input 
                                    type="text"
                                    value={searchVehiculo} 
                                    name="table_search" 
                                    className="form-control" 
                                    onChange={handleSearchVehiculo}
                                  />
                                </div>
                                <div className="col-sm-3 mb-2">
                                  <label>Chofer</label>
                                  <select className="form-control" 
                                    name="table_searc"
                                    onChange={handleSearchChofer} 
                                    value={searchChofer}
                                  >
                                    <option value=""></option>
                                    {choferes &&
                                        choferes.map((chofer) => (
                                          <option key={chofer.id} value={chofer.apellido}>
                                            {chofer.apellido}, {chofer.nombre}
                                          </option>
                                    ))}
                                  </select>
                                </div>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Listado de repartos</h3>
                        {/*}
                        <div className="card-tools">
                          <div className="input-group input-group-sm" style={{width: '180px'}}>
                            <input 
                              type="text"
                              value={busqueda} 
                              name="table_search" 
                              className="form-control float-right" 
                              placeholder="Cliente..." 
                              onChange={handleSearch}
                            />

                            <div className="input-group-append">
                              <button type="submit" className="btn btn-default">
                                <i className="fas fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        {*/}

                      </div>
                      <div className="card-body table-responsive p-0">
                      {repartos.length > 0 ? (
                        <table id="example1" className="table table-bordered table-striped">
                          <thead>
                          <tr>
                            <th>Fecha viaje</th>
                            <th>Cliente</th>
                            <th>Vehiculo</th>
                            <th>Chofer</th>
                            <th>Hola salida</th>
                            <th>Hora vuelta</th>
                            {currentRole === 2 && (
                            <th>Acciones</th>
                            )}
                          </tr>
                          </thead>
                          <tbody>
                          {records.map((reparto, index) => {
                            return (
                              <tr key={reparto.id}>
                                <td>{formateoFecha(reparto.fechaviaje)}</td>
                                <td>{reparto.cliente}</td>
                                <td>{reparto.vehiculo}</td>
                                <td>{reparto.apellido}, {reparto.nombre}</td>
                                <td>{reparto.horadesde}</td>
                                <td>{reparto.horahasta}</td>
                                {currentRole === 2 && (
                                <td>
                                  <button
                                      onClick={() => openModal(
                                        2,
                                        reparto.id,
                                        formateoFecha(reparto.fechaviaje),
                                        reparto.cliente,
                                        reparto.idvehiculo,
                                        reparto.idchofer,
                                        reparto.horadesde,
                                        reparto.horahasta,
                                        reparto.observacion
                                      )}
                                      className="btn btn-warning btn-xs" 
                                      data-toggle="modal" 
                                      data-target="#modal-lg"
                                      >
                                      Editar
                                  </button>
                                  {" "}
                                  <button
                                      onClick={() => deleteDeal(
                                        reparto.id,
                                      )}
                                      className="btn btn-danger btn-xs" 
                                      
                                      >
                                      Borrar
                                  </button>
                                </td>
                                )}
                              </tr>
                            );
                          })}
                          </tbody>
                        </table>
                        ) : (
                          <div className="row">
                            <div className="col-lg-12">
                              <p color="info" className="text-center">
                                  No se han encontrado repartos.
                              </p>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="card-footer clearfix">
                        <ul className="pagination pagination-sm m-0 float-right">
                          <li className="page-item">
                            <a className="page-link" href="#" onClick={prevPage}>&laquo;</a>
                          </li>
                          {
                            numbers.map((n,i) =>(
                            <li key={i} className={`page-item ${currentPage === n ? 'active' : ''}`}>
                              <a 
                                className="page-link" 
                                href="#"
                                onClick={()=>changeCPage(n)}
                              >{n}</a>
                            
                            </li>
                            ))
                          }
                          <li className="page-item">
                            <a className="page-link" href="#" onClick={nextPage}>&raquo;</a>
                          </li>
                        </ul>
                      </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
          )}

          <div className="modal fade" id="modal-lg">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                    <h4 className="modal-title">{title}</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                      <div className="modal-body">
                        <div className="card-body">
                            <div className="row mb-2">
                                <div className="col-sm-3">
                                  <input type="hidden" id="id" />
                                  <label>Fecha de viaje</label>
                                  <input
                                    id="fechaviaje" 
                                    name="fechaviaje"  
                                    type="date" 
                                    className="form-control datetimepicker-input"
                                    onChange={(e) => setFechaviaje(e.target.value)} 
                                    value={fechaviaje ? fechaviaje : ''}
                                  />
                                </div>
                                <div className="col-sm-9">
                                  <label>Cliente</label>
                                  <input
                                    id="cliente"
                                    name="cliente" 
                                    type="text" 
                                    className="form-control"
                                    onChange={(e) => setCliente(e.target.value)} 
                                    value={cliente ? cliente : ''}
                                  />
                                </div>
                                
                                
                            </div>

                            <div className="row mb-2">
                                <div className="col-sm-3">
                                  <label>Vehiculo</label>
                                  <select className="form-control" 
                                    id="idvehiculo"
                                    name="idvehiculo"
                                    onChange={(e) => setIdvehiculo(e.target.value)} 
                                    value={idvehiculo}
                                  >
                                    <option value=""></option>
                                    {vehiculos &&
                                        vehiculos.map((vehiculo) => (
                                          <option key={vehiculo.id} value={vehiculo.id}>
                                            {vehiculo.patente}
                                          </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-sm-3">
                                  <label>Chofer</label>
                                  <select className="form-control" 
                                    id="idchofer"
                                    name="idchofer"
                                    onChange={(e) => setIdchofer(e.target.value)} 
                                    value={idchofer}
                                  >
                                    <option value=""></option>
                                    {choferes &&
                                        choferes.map((chofer) => (
                                          <option key={chofer.id} value={chofer.id}>
                                            {chofer.apellido}, {chofer.nombre}
                                          </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-sm-3">
                                  <label>Hora salida</label>
                                  <input
                                    id="horadesde" 
                                    name="horadesde"
                                    type="time" 
                                    className="form-control"
                                    onChange={(e) => setHoraDesde(e.target.value)} 
                                    value={horadesde}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <label>Hora vuelta</label>
                                  <input
                                    id="horahasta" 
                                    name="horahasta" 
                                    type="time" 
                                    className="form-control"
                                    onChange={(e) => setHoraHasta(e.target.value)} 
                                    value={horahasta ? horahasta : ''}
                                  />
                                </div>
                            </div> 
                            
                            <div className="row mb-2">
                              <div className="col-sm-12">
                                <label>Observacion</label>
                                <textarea
                                  id="observacion" 
                                  name="observacion" 
                                  className="form-control" 
                                  rows="3"
                                  onChange={(e) => setObservacion(e.target.value)} 
                                  value={observacion}
                                >
                                  
                                </textarea>
                              </div>
                            </div>   

                        </div>
                      
                      </div>
                      
                      <div className="modal-footer pull-right">
                        <button type="submit" onClick={() => validate()} className="btn btn-primary">Guardar</button>
                        <button type="button" ref={closeModal} className="btn btn-default" data-dismiss="modal">Cerrar</button>
                      </div>
                </div>
                
                </div>
                
            </div>
      </div>
    </FullWidthLayout>
  );
};

export default Deals;
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export function showalert (message, icon, foco=''){
    onFocus(foco)
    const mySwal = withReactContent(Swal);
    mySwal.fire({
        title: message,
        icon: icon
    })
}

export const formateoFecha = (fecha, toShow = false) => {
    let fechaAltaC = fecha,
      separador = "T",
      arregloFechaAlta = fechaAltaC.split(separador);
    // Asigno la fecha formateada
    fecha = arregloFechaAlta[0];
  
    return toShow ? fecha.split("-").reverse().join("/") : fecha;
};

function onFocus(foco){
    if (foco !==''){
        document.getElementById(foco).focus()
    }
}

export const formateoFechaHora = (fecha) => {
    let fechaAltaC = fecha,
      separador = "T",
      arregloFechaAlta = fechaAltaC.split(separador);
    // Asigno la fecha formateada
    //console.log(arregloFechaAlta)
    fecha = arregloFechaAlta[0].split("-").reverse().join("/");
    let hora = `${arregloFechaAlta[1].split(".")[0]}`;
    const fechaHora = `${fecha} ${hora}`;
    return fechaHora;
  };
import axios from "axios";
import authHeader from "./authHeader";
import BASE_URL from '../conf'

const getAllUsers = async() => {
  return await axios.get(BASE_URL + "users", { headers: authHeader() });
};

const getUserById = async(id) => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return  await axios.get(BASE_URL + "users/" + `${id}`,requestOptions);
};

const updateUser = async(
  id,
  name, 
  lastname, 
  email, 
  password, 
  codigosector, 
  isactive, 
  createdat) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };
  return  await axios.put(BASE_URL + "users/" + `${id}`, {
    name, 
    lastname, 
    email, 
    password, 
    codigosector, 
    isactive, 
    createdat
  }, requestOptions);
};

const deleteUser= async(id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return  await axios.delete(BASE_URL + "users/" + `${id}`, requestOptions);
};

const usersService = {
  getAllUsers,
  getUserById,
  updateUser,
  deleteUser
};

export default usersService;
import React, { useState, useEffect, useRef} from "react";
import notificationService from "../../../services/notificacionesService";
import authService from "../../../services/authService";
import FullWidthLayout from "../../layouts/FullWidthLayout";

import { showalert } from "../../functions/Function";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const Notificacions = () => {

  const closeModal = useRef();

  const [notificaciones, setNotificaciones] = useState([]);
  const [searchTitulo, setSearchTitulo] = useState([]);
  const [searchDescripcion, setSearchDescripcion] = useState([]);
  const [tablaNotificaciones, setTablaNotificaciones]= useState([]);
  const [currentPage, setCurrentPage]= useState(1);
  const [loading, setLoading] = useState(undefined);

  const [id, setId] = useState('')
  const [titleNotification, setTitleNotification] = useState('')
  const [description, setDescription] = useState('')
  const [isactive, setIsActive] = useState('')

  const [operation, setOperation] = useState(1);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const user = authService.getCurrentUser();

    if (!user) {
      window.location.href="./";
    }

    getNotification();

  }, []);

  const getExpireToken = () => {
    if (!message){
      localStorage.removeItem("user");
      localStorage.removeItem("role");
      localStorage.removeItem("userId");
    }
    return window.location.href="./";
  }

  const getNotification = () => {
      try {
        notificationService.getAllNotifications()
        .then((response) => {
            setNotificaciones(response.data);
            setTablaNotificaciones(response.data);
            setLoading(true);
          },
          (error) => {
            console.log(error);
            setMessage(error.response.data.message)
            getExpireToken()
          }
        );
      } catch (err) {
        console.log(err);
      }
  }

  /* Busqueda por ... */
  const handleSearchTitulo = (e) =>{
    setSearchTitulo(e.target.value);
    filtrar(e.target.value);
  }

  const handleSearchDescripcion = (e) =>{
    setSearchDescripcion(e.target.value);
    filtrar(e.target.value);
  }

  const filtrar=(terminoBusqueda)=>{
    var resultadosBusqueda=tablaNotificaciones.filter((elemento)=>{
      if(
          elemento.title.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
       || elemento.description.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
      ){
        return elemento;
      }
    });
    setNotificaciones(resultadosBusqueda);
  }

  /* Fin Busqueda por  */


  const openModal = (
    op,
    id,
    titleNotification, 
    description, 
    isactive
    ) =>{
      setId('');
      setTitleNotification('');
      setDescription('');
      setIsActive('');

      setOperation(op);
      if (op === 1){
        setTitle("Nueva notificacion");
      }
      else if (op === 2){
        setTitle("Actualizar notificacion");
        setId(id)
        setTitleNotification(titleNotification);
        setDescription(description);
        setIsActive(isactive);
      }
  }

  const validate = () =>{
    if (titleNotification === ''){
      showalert('Titulo', 'warning')
    }
    else if (description === ''){
      showalert('Descripcion', 'warning')
    }
    else{
        enviarSolicitud();
    }
  }

  const enviarSolicitud = async() =>{
      if(operation === 1){
        notificationService
          .createNotification(
          titleNotification, 
          description, 
          isactive)
          .then(function(respuesta){
          var msg = respuesta.data.message;
          showalert(msg, 'success')
          onButtonClick();
          getNotification();
        })
        .catch(function(error){
          showalert('Error en la solicitud', 'error');
          console.log(error)
        });
      }else if (operation === 2){
        notificationService
          .updateNotification(
            id,
            titleNotification, 
            description, 
            isactive)
          .then(function(respuesta){
          var msg = respuesta.data.message;
          showalert(msg, 'success')
          onButtonClick();
          getNotification();
        })
        .catch(function(error){
          showalert('Error en la solicitud', 'error');
          console.log(error)
        });
      }
      
  }

  const deleteNotification = (id) => {
    const mySwal = withReactContent(Swal);
    mySwal.fire({
        title: '¿Seguro de eliminar la notificacion?',
        icon: 'question', 
        showCancelButton: true, confirmButtonText: 'Si, eliminar', cancelButtonText: 'Cancelar'
    })
    .then((result) => {
      if (result.isConfirmed){
        setId(id);
        notificationService.deleteNotification(id);
        getNotification();
      }else{
        showalert('La notificacion no fue eliminada.', 'warning');
      }
      
    })
    
  }

  const onButtonClick = () => {
    closeModal.current.click();
  };

  //PAGINADO
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;

  const records = notificaciones.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(notificaciones.length / recordsPerPage)

  const numbers = [...Array(nPage + 1).keys()].slice(1)

  const nextPage = () =>{
    if (currentPage !== 1){
      setCurrentPage(currentPage - 1)
    }
  }

  const changeCPage = (id) =>{
      setCurrentPage(id)
  }

  const prevPage = () =>{
    if (currentPage !== nPage){
      setCurrentPage(currentPage + 1)
    }
  }
  
  return (
    <FullWidthLayout>
      <div className="content-wrapper">
          <div className="content-header">
            <div className="container">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h3 className="m-0"> Notificaciones</h3>
                </div>
                <div className="col-sm-6">
                  <div className="float-sm-right">
                    <button
                        onClick={() => openModal(1)}
                        className="btn btn-primary btn-sm" 
                        data-toggle="modal" 
                        data-target="#modal-lg"
                        >
                        <i className="fa fa-plus" aria-hidden="true"></i> Nuevo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> 

          {!loading ? (
              <div className="custom-loader" style={{marginTop: '150px'}}></div>
          ) : (
          <div className="content">
            <div className="container">
                <div className="row mb-3">
                  <div className="col-lg-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Filtrar por</h3>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                
                                <div className="col-sm-6 mb-2">
                                  <label>Titulo</label>
                                  <input 
                                    type="text"
                                    value={searchTitulo} 
                                    name="table_search" 
                                    className="form-control" 
                                    onChange={handleSearchTitulo}
                                  />
                                </div>
                                <div className="col-sm-6 mb-2">
                                  <label>Descripcion</label>
                                  <input 
                                    type="text"
                                    value={searchDescripcion} 
                                    name="table_search" 
                                    className="form-control" 
                                    onChange={handleSearchDescripcion}
                                  />
                                </div>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
              
              <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Listado de notificaciones</h3>
                        {/*}
                        <div className="card-tools">
                          <div className="input-group input-group-sm" style={{width: '180px'}}>
                            <input 
                              type="text"
                              value={busqueda} 
                              name="table_search" 
                              className="form-control float-right" 
                              placeholder="Titulo..." 
                              onChange={handleSearch}
                            />

                            <div className="input-group-append">
                              <button type="submit" className="btn btn-default">
                                <i className="fas fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        {*/}
                      </div>
                      <div className="card-body table-responsive p-0">
                      {notificaciones.length > 0 ? (
                        <table id="example1" className="table table-bordered table-striped">
                          <thead>
                          <tr>
                            <th>Titulo</th>
                            <th>Descripcion</th>
                            <th>Activo</th>
                            <th>Acciones</th>
                          </tr>
                          </thead>
                          <tbody>
                          {records.map((notificacion, index) => {
                            return (
                              <tr key={notificacion.id}>
                                <td>{notificacion.title}</td>
                                <td>{notificacion.description}</td>
                                <td>
                                  {notificacion.isactive ?
                                  <span className="badge bg-success">Si</span>
                                  :
                                  <span className="badge bg-danger">No</span>}
                                </td>
                                <td>
                                  <button
                                      onClick={() => openModal(
                                        2,
                                        notificacion.id,
                                        notificacion.title,
                                        notificacion.description,
                                        notificacion.isactive
                                      )}
                                      className="btn btn-warning btn-xs" 
                                      data-toggle="modal" 
                                      data-target="#modal-lg"
                                      >
                                      Editar
                                  </button>
                                  {" "}
                                  <button
                                      onClick={() => deleteNotification(
                                        notificacion.id,
                                      )}
                                      className="btn btn-danger btn-xs" 
                                      
                                      >
                                      Borrar
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                          </tbody>
                        </table>
                        ) : (
                          <div className="row">
                            <div className="col-lg-12">
                              <p color="info" className="text-center">
                                  No se han encontrado notificaciones.
                              </p>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="card-footer clearfix">
                        <ul className="pagination pagination-sm m-0 float-right">
                          <li className="page-item">
                            <a className="page-link" href="#" onClick={prevPage}>&laquo;</a>
                          </li>
                          {
                            numbers.map((n,i) =>(
                            <li key={i} className={`page-item ${currentPage === n ? 'active' : ''}`}>
                              <a 
                                className="page-link" 
                                href="#"
                                onClick={()=>changeCPage(n)}
                              >{n}</a>
                            
                            </li>
                            ))
                          }
                          <li className="page-item">
                            <a className="page-link" href="#" onClick={nextPage}>&raquo;</a>
                          </li>
                        </ul>
                      </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
          )}

          <div className="modal fade" id="modal-lg">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                    <h4 className="modal-title">{title}</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                      <div className="modal-body">
                        <div className="card-body">

                            <div className="row mb-2">
                                <div className="col-sm-8">
                                  <label>Titulo</label>
                                  <input
                                    id="titleNotification"
                                    name="titleNotification" 
                                    type="text" 
                                    className="form-control"
                                    onChange={(e) => setTitleNotification(e.target.value)} 
                                    value={titleNotification ? titleNotification : ''}
                                  />
                                </div>
                                <div className="col-sm-4">
                                  <label>Activo</label>
                                  <select className="form-control" 
                                    id="isactive"
                                    name="isactive"
                                    onChange={(e) => setIsActive(e.target.value)} 
                                    value={isactive}
                                  >
                                    <option value=""></option>
                                    <option value={true}>Si</option>
                                    <option value={false}>No</option>
                                  </select>
                                </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-sm-12">
                                <label>Descripcion</label>
                                <textarea
                                  id="description" 
                                  name="description" 
                                  className="form-control" 
                                  rows="3"
                                  onChange={(e) => setDescription(e.target.value)} 
                                  value={description}
                                >
                                </textarea>
                              </div>
                            </div>  
                        </div>
                      
                      </div>
                      
                      <div className="modal-footer pull-right">
                        <button type="submit" onClick={() => validate()} className="btn btn-primary">Guardar</button>
                        <button type="button" ref={closeModal} className="btn btn-default" data-dismiss="modal">Cerrar</button>
                      </div>
                </div>
                
                </div>
                
            </div>
      </div>
    </FullWidthLayout>
  );
};

export default Notificacions;
import React, { useState, useEffect } from "react";
import authService from "../../../services/authService";
import { showalert } from "../../functions/Function";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(undefined);

  useEffect(() => {
    const user = authService.getCurrentUser();

    if (user) {
     window.location.href="/dashboard";
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
      try {
        await authService.login(username, password)
        .then(() => {
              setLoading(true);
              window.location.href="./dashboard";
            },
            (error) => {
              console.log(error);
              var msg = error.response.data.message;
              showalert(msg, 'warning')
            }
          );
      } catch (err) {
          console.log(err);
      }
  };

  return (
      <div className='hold-transition login-page'>
          <div className="login-box">
            <div className="login-logo">
            INTRANET
            </div>
            <div className="card">
              <div className="card-body login-card-body">
                <p className="login-box-msg">Inicia sesión para continuar</p>

                <form onSubmit={handleLogin}>
                  <div className="input-group mb-3">
                    <input 
                      type="text"
                      name="username" 
                      className="form-control" 
                      placeholder="Usuario" 
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-user"></span>
                      </div>
                    </div>
                  </div>

                  <div className="input-group mb-3">
                    <input 
                      type="password"
                      name="password" 
                      className="form-control" 
                      placeholder="Password"  
                      value={password}
                      onChange={(e) => setPassword(e.target.value)} 
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>

                  {loading ? (
                      <div className="custom-loader" style={{marginTop: '40px'}}></div>
                  ) : (
                  <div className="social-auth-links text-center mb-3">
                    <button 
                      type="submit" 
                      className="btn btn-block btn-primary"
                    >
                    Acceder
                    </button>
                  </div>
                  )}

                </form> 
              </div>
            </div>
          </div>
      </div>
  );
};

export default Login;
import axios from "axios";
import authHeader from "./authHeader";
import BASE_URL from '../conf'

const getAllZonasReparto = async() => {
  return await axios.get(BASE_URL + "zonasreparto", { headers: authHeader() });
};

const createZona = async(
  descripcion,
  isactive) => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return  await axios.post(BASE_URL + "zonasreparto", {
    descripcion,
    isactive
  }, requestOptions);
};

const updateZona = async(
  id,
  descripcion,
  isactive) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };
  return  await axios.put(BASE_URL + "zonasreparto/" + `${id}`, {
    descripcion,
    isactive
  }, requestOptions);
};

const deleteZona = async(id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return  await axios.delete(BASE_URL + "zonasreparto/" + `${id}`, requestOptions);
};

const filterZona = async(
  isactive) => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return  await axios.post(BASE_URL + "zonasreparto/filter", {
    isactive
  }, requestOptions);
};

const zonasRepartoService = {
  getAllZonasReparto,
  createZona,
  updateZona,
  deleteZona,
  filterZona
};

export default zonasRepartoService;
export default function authHeader() {
    const user = JSON.parse(localStorage.getItem("user"));

    //if (user && user.data.token) {
    if (user && user) {
      // return { Authorization: 'Bearer ' + user.accessToken };
      //return { "auth-token": user.token };
      return { "auth-token": user, "Content-Type": "application/json" };
    } else {
      return {};
    }
  }
import React, { useState, useEffect, useRef} from "react";
import OrderService from "../../../services/orderService";
import zonasRepartoService from "../../../services/zonasRepartoService";
import estadoPedidosService from "../../../services/estadoPedidosService";
import authService from "../../../services/authService";
import FullWidthLayout from "../../layouts/FullWidthLayout";

import { showalert, formateoFecha, formateoFechaHora } from "../../functions/Function";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const Orders = () => {

  const closeModal = useRef();

  const [orders, setOrders] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [estados, setEstados] = useState([]);
  const [searchFechaPedido, setSearchFechaPedido] = useState([]);
  const [searchCliente, setSearchCliente] = useState([]);
  const [searchNv, setSearchNv] = useState([]);

  const [tablaUsuarios, setTablaUsuarios]= useState([]);
  const [currentPage, setCurrentPage]= useState(1);
  const [loading, setLoading] = useState(undefined);

  const [id, setId] = useState('')
  const [fechapedido, setFechaPedido] = useState('')
  const [cliente, setCliente] = useState('')
  const [nv, setNv] = useState('')
  const [cantmetros, setCantMetros] = useState('')
  const [referencia, setReferencia] = useState('')
  const [pospuesto, setPospuesto] = useState('')
  const [remite, setRemite] = useState('')
  const [idzonareparto, setIdZonaReparto] = useState('')
  const [idestadopedido, setIdEstadoPedido] = useState('')
  const [observacion, setObservacion] = useState('')
  const [idusuario, setIdUsuario] = useState(1)
  const [fechaentrega, setFechaEntrega] = useState('')

  const [operation, setOperation] = useState(1);
  const [title, setTitle] = useState('');

  const [currentRole, setCurrentRole] = useState(null);
  const [message, setMessage] = useState(null);

  const [historyOrders, setHistoryOrders]= useState([]);

  useEffect(() => {
    const user = authService.getCurrentUser();
    const role = authService.getCurrentRole();

    if (!user) {
      window.location.href="./";
    }

    if (role) {
      setCurrentRole(role);
    }

    getOrders();
    getEstadoPedidos();
    getZonasReparto();

  }, []);

  const getExpireToken = () => {
    if (!message){
      localStorage.removeItem("user");
      localStorage.removeItem("role");
      localStorage.removeItem("userId");
    }
    return window.location.href="./";
  }

  const getZonasReparto = () => {
    try {
      zonasRepartoService.filterZona(true)
      .then((response) => {
          setZonas(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  const getEstadoPedidos = () => {
    try {
      estadoPedidosService.filterEstadoPedido(true)
      .then((response) => {
        setEstados(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  const getOrders = () => {
      try {
        OrderService.getAllOrders()
        .then((response) => {
            setOrders(response.data);
            setTablaUsuarios(response.data);
            setLoading(true);
          },
          (error) => {
            console.log(error);
            setMessage(error.response.data.message)
            getExpireToken()
          }
        );
      } catch (err) {
        console.log(err);
      }
  }

  /* Busqueda por nota de pedido */

  const handleSearchFechaPedido = (e) =>{
    setSearchFechaPedido(e.target.value);
    filtrar(e.target.value);
  }

  const handleSearchCliente = (e) =>{
    setSearchCliente(e.target.value);
    filtrar(e.target.value);
  }

  const handleSearchNv = (e) =>{
    setSearchNv(e.target.value);
    filtrar(e.target.value);
  }

  const filtrar=(terminoBusqueda)=>{
    var resultadosBusqueda=tablaUsuarios.filter((elemento)=>{
      if(
          elemento.fechapedido.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
       || elemento.cliente.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
       || elemento.nv.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
      ){
        return elemento;
      }
    });
    setOrders(resultadosBusqueda);
  }

  /* Fin Busqueda por nota de pedido */


  const openModal = (
    op,
    id,
    fechapedido, 
    cliente,
    nv,
    cantmetros,
    referencia, 
    pospuesto, 
    remite, 
    idzonareparto, 
    idestadopedido, 
    observacion,
    idusuario,
    fechaentrega
    ) =>{
      setId('');
      setFechaPedido('');
      setCliente('');
      setNv('');
      setCantMetros('');
      setReferencia('');
      setPospuesto('');
      setRemite('');
      setIdZonaReparto('');
      setIdEstadoPedido('');
      setObservacion('');
      setIdUsuario('');
      setFechaEntrega('');

      setOperation(op);
      if (op === 1){
        setTitle("Nuevo pedido");
        setIdUsuario(1);
      }
      else if (op === 2){
        setTitle("Actualizar pedido");
        setId(id)
        setFechaPedido(fechapedido);
        setCliente(cliente);
        setNv(nv);
        setCantMetros(cantmetros);
        setReferencia(referencia);
        setPospuesto(pospuesto);
        setRemite(remite);
        setIdZonaReparto(idzonareparto);
        setIdEstadoPedido(idestadopedido);
        setObservacion(observacion);
        setIdUsuario(1);
        setFechaEntrega(fechaentrega)
      }
  }

  const openModalHistory = (id) =>{
      setId('');
      setHistoryOrders('')
      setTitle("Historial de cambios");
      try {
        OrderService.historyOrder(id)
        .then((response) => {
            setHistoryOrders(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (err) {
        console.log(err);
      }
  }

  const validate = () =>{
    if (fechapedido === ''){
      showalert('Fecha del pedido', 'warning')
    }
    else if (cliente === ''){
      showalert('Nombre del cliente', 'warning')
    }
    else if (nv === ''){
      showalert('Nota de venta', 'warning')
    }
    else if (cantmetros === ''){
      showalert('Metros cubicos', 'warning')
    }
    else if (pospuesto === ''){
      showalert('Pospuesto', 'warning')
    }
    else if (remite === ''){
      showalert('Remite', 'warning')
    }
    else if (idzonareparto === ''){
      showalert('Zona de entrega', 'warning')
    }
    else if (idestadopedido === ''){
      showalert('Estado del pedido', 'warning')
    }
    else if (fechaentrega === ''){
      showalert('Fecha de entrega', 'warning')
    }
    else{
        enviarSolicitud();
    }
  }

  const enviarSolicitud = async() =>{
      if(operation === 1){
        OrderService
          .createOrder(
            fechapedido, 
            cliente, 
            nv,
            cantmetros,
            referencia, 
            pospuesto, 
            remite, 
            idzonareparto, 
            idestadopedido, 
            observacion,
            idusuario,
            fechaentrega)
          .then(function(respuesta){
          var msg = respuesta.data.message;
          showalert(msg, 'success')
          onButtonClick();
          getOrders();
        })
        .catch(function(error){
          showalert('Error en la solicitud', 'error');
          console.log(error)
        });
      }else if (operation === 2){
        OrderService
          .updateOrder(
            id,
            fechapedido, 
            cliente, 
            nv,
            cantmetros,
            referencia,
            pospuesto, 
            remite, 
            idzonareparto, 
            idestadopedido, 
            observacion,
            idusuario,
            fechaentrega)
          .then(function(respuesta){
          var msg = respuesta.data.message;
          showalert(msg, 'success')
          onButtonClick();
          getOrders();
        })
        .catch(function(error){
          showalert('Error en la solicitud', 'error');
          console.log(error)
        });
      }
      
  }

  const deleteOrder = (id) => {
    const mySwal = withReactContent(Swal);
    mySwal.fire({
        title: '¿Seguro de eliminar el pedido?',
        icon: 'question', 
        showCancelButton: true, confirmButtonText: 'Si, eliminar', cancelButtonText: 'Cancelar'
    })
    .then((result) => {
      if (result.isConfirmed){
        setId(id);
        OrderService.deleteOrder(id);
        getOrders();
      }else{
        showalert('El pedido no fue eliminado.', 'warning');
      }
      
    })
    
  }

  const onButtonClick = () => {
    closeModal.current.click();
  };

  //PAGINADO
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;

  const records = orders.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(orders.length / recordsPerPage)

  const numbers = [...Array(nPage + 1).keys()].slice(1)

  const nextPage = () =>{
    if (currentPage !== 1){
      setCurrentPage(currentPage - 1)
    }
  }

  const changeCPage = (id) =>{
      setCurrentPage(id)
  }

  const prevPage = () =>{
    if (currentPage !== nPage){
      setCurrentPage(currentPage + 1)
    }
  }
  
  return (
    <FullWidthLayout>
      <div className="content-wrapper">
          <div className="content-header">
            <div className="container">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h3 className="m-0"> Pedidos</h3>
                </div>
                {currentRole === 1 && (
                <div className="col-sm-6">
                  <div className="float-sm-right">
                    <button
                        onClick={() => openModal(1)}
                        className="btn btn-primary btn-sm" 
                        data-toggle="modal" 
                        data-target="#modal-lg"
                        >
                        <i className="fa fa-plus" aria-hidden="true"></i> Nuevo
                    </button>
                  </div>
                </div>
                )}
              </div>
            </div>
          </div> 

          {!loading ? (
              <div className="custom-loader" style={{marginTop: '150px'}}></div>
          ) : (
          <div className="content">
            <div className="container">

              <div className="row mb-3">
                  <div className="col-lg-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Filtrar por</h3>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-4 mb-2">
                                  <label>Fecha de pedido</label>
                                  <input
                                    type="date"
                                    value={searchFechaPedido}  
                                    name="table_search" 
                                    className="form-control datetimepicker-input"
                                    onChange={handleSearchFechaPedido}
                                  />
                                </div>
                                <div className="col-sm-4 mb-2">
                                  <label>Cliente</label>
                                  <input 
                                    type="text"
                                    value={searchCliente} 
                                    name="table_search" 
                                    className="form-control" 
                                    onChange={handleSearchCliente}
                                  />
                                </div>
                                <div className="col-sm-4 mb-2">
                                  <label>Nota venta</label>
                                  <input 
                                    type="text"
                                    value={searchNv} 
                                    name="table_search" 
                                    className="form-control" 
                                    onChange={handleSearchNv}
                                  />
                                </div>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Listado de pedidos</h3>
                        <div className="card-tools">
                          {/*}
                          <div className="input-group input-group-sm" style={{width: '180px'}}>
                            <input 
                              type="text"
                              value={busqueda} 
                              name="table_search" 
                              className="form-control float-right" 
                              placeholder="Cliente o NV Nro..." 
                              onChange={handleSearch}
                            />

                            <div className="input-group-append">
                              <button type="submit" className="btn btn-default">
                                <i className="fas fa-search"></i>
                              </button>
                            </div>
                          </div>
                          {*/}
                        </div>
                      </div>
                      <div className="card-body table-responsive p-0">
                      {orders.length > 0 ? (
                        <table id="example1" className="table table-bordered table-striped">
                          <thead>
                          <tr>
                            <th>Fecha pedido</th>
                            <th>Fecha entrega</th>
                            <th>Cliente</th>
                            <th>Nota venta</th>
                            <th>M3</th>
                            <th>Pospuesto</th>
                            <th>Remite</th>
                            <th>Zona</th>
                            <th>Estado</th>
                            <th>Acciones</th>
                          </tr>
                          </thead>
                          <tbody>
                          {records.map((order, index) => {
                            return (
                              <tr key={order.id}>
                                <td>{formateoFecha(order.fechapedido)}</td>
                                <td>{formateoFecha(order.fechaentrega)}</td>
                                <td>{order.cliente}</td>
                                <td>{order.nv}</td>
                                <td>{order.cantmetros}</td>
                                <td>
                                  {order.pospuesto ?
                                  <span className="badge bg-warning">Si</span>
                                  :
                                  <span className="badge bg-success">No</span>}
                                </td>
                                <td>
                                  {order.remite ?
                                  <span className="badge bg-warning">Si</span>
                                  :
                                  <span className="badge bg-success">No</span>}
                                </td>
                                
                                <td>{order.zona}</td>
                                <td>{order.estado}</td>
                                <td>
                                  <button
                                      onClick={() => openModal(
                                        2,
                                        order.id,
                                        formateoFecha(order.fechapedido),
                                        order.cliente,
                                        order.nv,
                                        order.cantmetros,
                                        order.referencia,
                                        order.pospuesto,
                                        order.remite,
                                        order.idzonareparto,
                                        order.idestadopedido,
                                        order.observacion,
                                        order.idusuario,
                                        formateoFecha(order.fechaentrega)

                                      )}
                                      className="btn btn-warning btn-xs" 
                                      data-toggle="modal" 
                                      data-target="#modal-lg"
                                      >
                                      Editar
                                  </button>
                                  {" "}
                                  {currentRole === 1 && (
                                  <button
                                      onClick={() => openModalHistory(
                                        order.id
                                      )}
                                      className="btn btn-secondary btn-xs"
                                      data-toggle="modal" 
                                      data-target="#modal-history"
                                      >
                                      Historial
                                  </button>
                                  )}
                                  {" "}
                                  {currentRole === 1 && (
                                  <button
                                      onClick={() => deleteOrder(
                                        order.id,
                                      )}
                                      className="btn btn-danger btn-xs" 
                                      
                                      >
                                      Borrar
                                  </button>
                                  )}
                                  
                                </td>
                              </tr>
                            );
                          })}
                          </tbody>
                        </table>
                        ) : (
                          <div className="row">
                            <div className="col-lg-12">
                              <p color="info" className="text-center">
                                  No se han encontrado ordenes.
                              </p>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="card-footer clearfix">
                        <ul className="pagination pagination-sm m-0 float-right">
                          <li className="page-item">
                            <a className="page-link" href="#" onClick={prevPage}>&laquo;</a>
                          </li>
                          {
                            numbers.map((n,i) =>(
                            <li key={i} className={`page-item ${currentPage === n ? 'active' : ''}`}>
                              <a 
                                className="page-link" 
                                href="#"
                                onClick={()=>changeCPage(n)}
                              >{n}</a>
                            
                            </li>
                            ))
                          }
                          <li className="page-item">
                            <a className="page-link" href="#" onClick={nextPage}>&raquo;</a>
                          </li>
                        </ul>
                      </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
          )}

          <div className="modal fade" id="modal-lg">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                    <h4 className="modal-title">{title}</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                      <div className="modal-body">
                        <div className="card-body">
                            <div className="row mb-2">
                                <div className="col-sm-3">
                                  <input type="hidden" id="id" />
                                  <label>Fecha pedido</label>
                                  <input
                                    disabled={currentRole !== 1}
                                    id="fechapedido" 
                                    name="fechapedido"  
                                    type="date" 
                                    className="form-control datetimepicker-input"
                                    onChange={(e) => setFechaPedido(e.target.value)} 
                                    value={fechapedido ? fechapedido : ''}
                                  />
                                </div>
                                <div className="col-sm-6">
                                  <label>Cliente</label>
                                  <input
                                    disabled={currentRole !== 1}
                                    id="cliente"
                                    name="cliente" 
                                    type="text" 
                                    className="form-control"
                                    onChange={(e) => setCliente(e.target.value)} 
                                    value={cliente ? cliente : ''}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <label>Nota de venta</label>
                                  <input
                                    disabled={currentRole !== 1}
                                    id="notaventa" 
                                    name="notaventa" 
                                    type="number" 
                                    className="form-control"
                                    onChange={(e) => setNv(e.target.value)} 
                                    value={nv ? nv : ''}
                                  />
                                </div>
                                
                            </div>

                            <div className="row mb-2">
                                <div className="col-sm-3">
                                  <label>Metros cubicos</label>
                                  <input
                                    disabled={currentRole !== 1}
                                    id="cantmetros" 
                                    name="cantmetros"
                                    type="number" 
                                    className="form-control"
                                    onChange={(e) => setCantMetros(e.target.value)} 
                                    value={cantmetros}
                                  />
                                </div>
                                <div className="col-sm-6">
                                  <label>Referencia</label>
                                  <input
                                    disabled={currentRole !== 1}
                                    id="referencia"
                                    name="referencia" 
                                    type="text" 
                                    className="form-control"
                                    onChange={(e) => setReferencia(e.target.value)} 
                                    value={referencia}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <label>Pospuesto</label>
                                  <select className="form-control"
                                    disabled={currentRole !== 1} 
                                    id="pospuesto"
                                    name="pospuesto"
                                    onChange={(e) => setPospuesto(e.target.value)} 
                                    value={pospuesto}
                                  >
                                    <option value=""></option>
                                    <option value={true}>Si</option>
                                    <option value={false}>No</option>
                                  </select>
                                </div>
                            </div> 
                            <div className="row mb-2">
                                <div className="col-sm-3">
                                  <label>Remite</label>
                                  <select
                                    disabled={currentRole !== 1} 
                                    className="form-control"
                                    id="remite" 
                                    name="remite"
                                    onChange={(e) => setRemite(e.target.value)} 
                                    value={remite}
                                  >
                                    <option value=""></option>
                                    <option value={true}>Si</option>
                                    <option value={false}>No</option>
                                  </select>
                                  
                                </div>
                                <div className="col-sm-3">
                                  <label>Zona de entrega</label>
                                  <select
                                    disabled={currentRole !== 1} 
                                    className="form-control"
                                    id="idzonareparto" 
                                    name="idzonareparto"
                                    onChange={(e) => setIdZonaReparto(e.target.value)} 
                                    value={idzonareparto}
                                  >
                                    <option value=""></option>
                                      {zonas &&
                                        zonas.map((zona) => (
                                          <option key={zona.id} value={zona.id}>
                                            {zona.descripcion}
                                          </option>
                                    ))}
                                  </select>                                 
                                </div>
                                <div className="col-sm-3">
                                  <label>Estado</label>
                                  <select 
                                    className="form-control"
                                    id="idestadopedido" 
                                    name="idestadopedido"
                                    onChange={(e) => setIdEstadoPedido(e.target.value)} 
                                    value={idestadopedido}
                                  >
                                    <option value=""></option>
                                      {estados &&
                                        estados.map((estado) => (
                                          <option key={estado.id} value={estado.id}>
                                            {estado.descripcion}
                                          </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-sm-3">
                                  <label>Fecha entrega</label>
                                  <input
                                    disabled={currentRole !== 1}
                                    id="fechaentrega" 
                                    name="fechaentrega"  
                                    type="date" 
                                    className="form-control datetimepicker-input"
                                    onChange={(e) => setFechaEntrega(e.target.value)} 
                                    value={fechaentrega ? fechaentrega : ''}
                                  />
                                </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-sm-12">
                                <label>Observacion</label>
                                <textarea
                                  id="observacion" 
                                  name="observacion" 
                                  className="form-control" 
                                  rows="3"
                                  onChange={(e) => setObservacion(e.target.value)} 
                                  value={observacion}
                                >
                                  
                                </textarea>
                              </div>
                            </div>   

                        </div>
                      
                      </div>
                      
                      <div className="modal-footer pull-right">
                        <button type="submit" onClick={() => validate()} className="btn btn-primary">Guardar</button>
                        <button type="button" ref={closeModal} className="btn btn-default" data-dismiss="modal">Cerrar</button>
                      </div>
                </div>
              </div>
          </div>

          <div className="modal fade" id="modal-history">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                    <h4 className="modal-title">{title}</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                      <div className="modal-body">
                        <div className="card-body">
                            
                                
                            {historyOrders.length > 0 ? (
                              <table id="example1" className="table table-bordered table-striped">
                                <thead>
                                <tr>
                                  <th>Fecha y hora</th>
                                  <th>Estado</th>
                                  <th>Usuario</th>
                                </tr>
                                </thead>
                                <tbody>
                                {historyOrders.map((history, index) => {
                                  return (
                                    <tr key={history.id}>
                                      <td>{formateoFechaHora(history.timestamp)}</td>
                                      <td>{history.estado}</td>
                                      <td>{history.nombre_usuario}, {history.apellido_usuario}</td>
                                    </tr>
                                  );
                                })}
                                </tbody>
                              </table>
                              ) : (
                                <div className="row">
                                  <div className="col-lg-12">
                                    <p color="info" className="text-center">
                                        Sin movimientos registrados.
                                    </p>
                                  </div>
                                </div>
                              )}

                            </div>

                        
                      </div>
                      
                      <div className="modal-footer pull-right">
                        <button type="button" ref={closeModal} className="btn btn-default" data-dismiss="modal">Cerrar</button>
                      </div>
                </div>
              </div>
          </div>

      </div>

    </FullWidthLayout>
  );
};

export default Orders;
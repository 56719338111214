import axios from "axios";
import authHeader from "./authHeader";
import BASE_URL from '../conf'

const getAllDeals = async() => {
  return await axios.get(BASE_URL + "deals", { headers: authHeader() });
};

const createDeal = async(
  fechaviaje, 
  cliente, 
  idvehiculo, 
  idchofer, 
  horadesde, 
  horahasta, 
  observacion) => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return  await axios.post(BASE_URL + "deals", {
    fechaviaje, 
    cliente, 
    idvehiculo, 
    idchofer, 
    horadesde, 
    horahasta, 
    observacion
  }, requestOptions);
};

const updateDeal = async(
  id,
  fechaviaje, 
  cliente, 
  idvehiculo, 
  idchofer, 
  horadesde, 
  horahasta, 
  observacion) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };
  return  await axios.put(BASE_URL + "deals/" + `${id}`, {
    fechaviaje, 
    cliente, 
    idvehiculo, 
    idchofer, 
    horadesde, 
    horahasta, 
    observacion
  }, requestOptions);
};

const deleteDeal= async(id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return  await axios.delete(BASE_URL + "deals/" + `${id}`, requestOptions);
};

const dealsService = {
  getAllDeals,
  createDeal,
  updateDeal,
  deleteDeal
};

export default dealsService;
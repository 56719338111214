import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

//Services
import AuthService from "./services/authService";

//Components
import Login from "./components/pages/auth/Login";
import Dashboard from "./components/pages/dashboard/Dashboard";
import Orders from "./components/pages/orders/Orders";
import Deals from "./components/pages/deals/Deals";
import Document from "./components/pages/document/Document";
import Users from "./components/pages/users/Users";
import Drivers from "./components/pages/drivers/Drivers";
import Profile from "./components/pages/users/profile/Profile";
import Notificacions from "./components/pages/notifications/Notificacions";
import Zonas from "./components/pages/zonas/Zonas";
import Estados from "./components/pages/estados/Estados";
import Vehicle from "./components/pages/vehiculos/Vehiculos";

function App() {
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
    }
  }, []);

  return (
    <div>
        <div className='wrapper'>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/deals" element={<Deals />} />
              <Route path="/documentation" element={<Document />} />
              <Route path="/notifications" element={<Notificacions />} />
              <Route path="/users" element={<Users />} />
              <Route path="/drivers" element={<Drivers />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/zonas" element={<Zonas />} />
              <Route path="/estados" element={<Estados />} />
              <Route path="/vehicle" element={<Vehicle />} />
            </Routes>
        </div> 
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import authService from "../../../../services/authService";
import usersService from "../../../../services/usuariosService";
import FullWidthLayout from "../../../layouts/FullWidthLayout";
import { showalert } from "../../../functions/Function";
import profile from '../../../../assets/images/user2-160x160.jpg'

const Profile = () => {
  const [currentUserData, setCurrentUserData] = useState([]);
  const [loading, setLoading] = useState(undefined);

  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [lastname, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [resetPassword, setResetPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [codigosector, setCodigoSector] = useState('')
  const [isactive, setIsActive] = useState('')
  const [createdat, setCreatedAt] = useState('')
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const user = authService.getCurrentUser();
    
    if (!user) {
      window.location.href="./";
    }

    getUser()

  }, []);

  const getExpireToken = () => {
    if (!message){
      localStorage.removeItem("user");
      localStorage.removeItem("role");
      localStorage.removeItem("userId");
    }
    return window.location.href="./";
  }

  const getUser = () => {
    const userId = authService.getCurrentUserId();
    try {
      usersService.getUserById(userId)
      .then((response) => {
          setCurrentUserData(response.data[0]);
          setId(response.data[0].id)
          setName(response.data[0].name)
          setLastName(response.data[0].lastname)
          setEmail(response.data[0].email)
          setCodigoSector(response.data[0].codigosector)
          setIsActive(response.data[0].isactive)
          setCreatedAt(response.data[0].createdat)
          setResetPassword('')
          setConfirmPassword('')
          setLoading(true);
        },
        (error) => {
          console.log(error);
          setMessage(error.response.data.message)
          getExpireToken()
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

 const updateUser = (id, name, lastname, email, resetPassword, codigosector, isactive, createdat) => {
    if (resetPassword !== confirmPassword){
      showalert('Las contraseñas no coinciden', 'warning')
    }
    else if (resetPassword.length < 6){
      showalert('La contraseña debe contener al menos 6 caracteres', 'warning')
    }
    else{
      usersService
        .updateUser(
          id,
          name,
          lastname,
          email, 
          resetPassword, 
          codigosector, 
          isactive, 
          createdat)
        .then(function(respuesta){
        var msg = respuesta.data.message;
        showalert(msg, 'success')
        getUser();
      })
      .catch(function(error){
        showalert('Error en la solicitud', 'error');
        console.log(error)
      });
    }
  
  }

  return (
    
    <FullWidthLayout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h3 className="m-0"> Mi perfil </h3>
              </div>
              
            </div>
          </div>
        </div>

        {!loading ? (
              <div className="custom-loader" style={{marginTop: '150px'}}></div>
          ) : (
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-3">

                <div className="card card-outline">
                  <div className="card-body box-profile">
                    <div className="text-center">
                      <img className="profile-user-img img-fluid img-circle" 
                          src={profile}
                          alt="User profile" />
                    </div>

                    <h3 className="profile-username text-center">{currentUserData.lastname}, {currentUserData.name} </h3>

                    <p className="text-muted text-center">
                        {currentUserData.codigosector === 1 ? 'Oficina'
                          : currentUserData.codigosector === 2 ? 'Despacho' 
                          : 'Produccion'
                        }
                    </p>

                  </div>
                </div>
              </div>

              <div className="col-md-9">
                <div className="card">
                  <div className="card-body">
                    <div className="tab-content">
                      
                        <div className="form-horizontal">
                          {/*}
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Nombre</label>
                            <div className="col-sm-9">
                              <input
                                  id="name"
                                  name="name" 
                                  type="text" 
                                  className="form-control"
                                  onChange={(e) => setName(e.target.value)} 
                                  value={name ? name : ''}
                                />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label  className="col-sm-3 col-form-label">Apellido</label>
                            <div className="col-sm-9">
                                <input
                                  id="lastname"
                                  name="lastname" 
                                  type="text" 
                                  className="form-control"
                                  onChange={(e) => setLastName(e.target.value)} 
                                  value={lastname ? lastname : ''}
                                />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label  className="col-sm-3 col-form-label">Email</label>
                            <div className="col-sm-9">
                              <input
                                  id="email"
                                  name="email" 
                                  type="email" 
                                  className="form-control"
                                  onChange={(e) => setEmail(e.target.value)} 
                                  value={email ? email : ''}
                                />
                            </div>
                          </div>
                          {*/}
                          <div className="form-group row">
                            <label  className="col-sm-3 col-form-label">Nueva contraseña</label>
                            <div className="col-sm-9">
                                <input
                                  id="resetPassword"
                                  name="resetPassword" 
                                  type="password" 
                                  className="form-control"
                                  onChange={(e) => setResetPassword(e.target.value)} 
                                  value={resetPassword ? resetPassword : ''}
                                />
                            </div>
                          </div>
                          
                          <div className="form-group row pb-4">
                            <label  className="col-sm-3 col-form-label">Confirmar contraseña</label>
                            <div className="col-sm-9">
                                <input
                                  id="re-password"
                                  name="re-password" 
                                  type="password" 
                                  className="form-control"
                                  onChange={(e) => setConfirmPassword(e.target.value)} 
                                  value={confirmPassword ? confirmPassword : ''}
                                />
                            </div>
                          </div>

                          <div className="form-group row">
                            <div className="offset-sm-3 col-sm-9">
                              <button 
                                disabled={!resetPassword}
                                onClick={() => updateUser(
                                  id, 
                                  name, 
                                  lastname, 
                                  email, 
                                  resetPassword, 
                                  codigosector, 
                                  isactive, 
                                  createdat
                                )} 
                                type="submit" 
                                className="btn btn-primary btn-sm"
                              >
                                Actualizar 
                              </button>
                            </div>
                          </div>
                        </div>
                     
                    </div>
                  </div>

                </div>
                </div>

         



            </div>
          </div>
        </div>
        )}
  
      </div>
    </FullWidthLayout>
  );
};

export default Profile;
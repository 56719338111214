import axios from "axios";
import authHeader from "./authHeader";
import BASE_URL from '../conf'

const getAllVehiculos = async() => {
  return await axios.get(BASE_URL + "vehicles", { headers: authHeader() });
};

const createVehiculo = async(
  patente, 
  marcamodelo, 
  cantmetros, 
  observacion, 
  isactive) => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return  await axios.post(BASE_URL + "vehicles", {
    patente, 
    marcamodelo, 
    cantmetros, 
    observacion, 
    isactive
  }, requestOptions);
};

const updateVehiculo = async(
  id,
  patente, 
  marcamodelo, 
  cantmetros, 
  observacion, 
  isactive) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };
  return  await axios.put(BASE_URL + "vehicles/" + `${id}`, {
    patente, 
    marcamodelo, 
    cantmetros, 
    observacion, 
    isactive
  }, requestOptions);
};

const deleteVehiculo = async(id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return  await axios.delete(BASE_URL + "vehicles/" + `${id}`, requestOptions);
};

const filterVehiculo = async(
  isactive) => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return  await axios.post(BASE_URL + "vehicles/filter", {
    isactive
  }, requestOptions);
};


const vehiculosService = {
  getAllVehiculos,
  createVehiculo,
  updateVehiculo,
  deleteVehiculo,
  filterVehiculo
};

export default vehiculosService;
import axios from "axios";
import authHeader from "./authHeader";
import BASE_URL from '../conf'

const getAllChoferes = async() => {
  return await axios.get(BASE_URL + "drivers", { headers: authHeader() });
};

const createChofer = async(
  nombre,
  apellido, 
  claselicencia, 
  vtolicencia, 
  grupofactor, 
  celular, 
  observacion, 
  isactive) => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return  await axios.post(BASE_URL + "drivers", {
    nombre,
    apellido, 
    claselicencia, 
    vtolicencia, 
    grupofactor, 
    celular, 
    observacion, 
    isactive
  }, requestOptions);
};

const updateChofer = async(
  id,
  nombre,
  apellido, 
  claselicencia, 
  vtolicencia, 
  grupofactor, 
  celular, 
  observacion, 
  isactive) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };
  return  await axios.put(BASE_URL + "drivers/" + `${id}`, {
    nombre,
    apellido, 
    claselicencia, 
    vtolicencia, 
    grupofactor, 
    celular, 
    observacion, 
    isactive
  }, requestOptions);
};

const deleteChofer = async(id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return  await axios.delete(BASE_URL + "drivers/" + `${id}`, requestOptions);
};

const filterChofer = async(
  isactive) => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return  await axios.post(BASE_URL + "drivers/filter", {
    isactive
  }, requestOptions);
};

const choferesService = {
  getAllChoferes,
  createChofer,
  updateChofer,
  deleteChofer,
  filterChofer
};

export default choferesService;
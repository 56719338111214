import axios from "axios";
import authHeader from "./authHeader";
import BASE_URL from '../conf'

const getAllEstadoPedidos = async() => {
  return await axios.get(BASE_URL + "estadopedidos", { headers: authHeader() });
};

const createEstadoPedido = async(
  descripcion,
  isactive) => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return  await axios.post(BASE_URL + "estadopedidos", {
    descripcion,
    isactive
  }, requestOptions);
};

const updateEstadoPedido = async(
  id,
  descripcion,
  isactive) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };
  return  await axios.put(BASE_URL + "estadopedidos/" + `${id}`, {
    descripcion,
    isactive
  }, requestOptions);
};

const deleteEstadoPedido = async(id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return  await axios.delete(BASE_URL + "estadopedidos/" + `${id}`, requestOptions);
};

const filterEstadoPedido = async(
  isactive) => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return  await axios.post(BASE_URL + "estadopedidos/filter", {
    isactive
  }, requestOptions);
};


const estadoPedidosService = {
  getAllEstadoPedidos,
  createEstadoPedido,
  updateEstadoPedido,
  deleteEstadoPedido,
  filterEstadoPedido
};

export default estadoPedidosService;